import {
  getRoomEndpoint,
  getRoomsEndpoint,
  addRoomEndpoint,
  editRoomEndpoint,
  getRoomReservationsEndpoint,
  generalReportsRoomsEndpoint,
  removeReservationsEndpoint,
} from './endpoints'

import { optionsGET, optionsPOST, optionsPUT, optionsDELETE } from './options'

const roomService = {

  // Consulta la lista de salas disponibles
  getRooms: () => fetch(getRoomsEndpoint(), optionsGET())
  .then((response) => {
    if(!response.ok) {
      return Promise.reject(response.statusText)
    }
    return response.json()
  }),

  // Consulta la lista de reservas hechas por cada sala
  getRoomsReservations: (day) => fetch(getRoomReservationsEndpoint(day), optionsGET())
  .then((response) => {
    if(!response.ok) {
      return Promise.reject(response.statusText)
    }
    return response.json();
  }),

  // Obtiene informacion de una sala
  getRoom: () => fetch(getRoomEndpoint(), optionsGET())
    .then((response) => {
      if(!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json()
    }),

  // Crea una nueva sala
  addRoom: (data) => fetch(addRoomEndpoint(), optionsPOST(data))
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    }),

    // edita los datos de una sala especifico
  editRoom: (data) => fetch(editRoomEndpoint(data.id), optionsPUT(data))
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    }),

  generalReportsRoom: (params) => fetch(generalReportsRoomsEndpoint(params), optionsGET())
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    }),

  removeReservation: (params) => fetch(removeReservationsEndpoint(params), optionsDELETE())
    .then((response) => {
      if (!response.ok) {
        return {
          success: false,
          message: response.statusText
        };
      }
      return {
        success: true,
        message: 'Se eliminó la reserva correctamente'
      };
    }),
  
/*   removeSystemUser: (id) => fetch(removeSystemUsersEndpoint(id), optionsDELETE())
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response
    }) */
}

export default roomService
