import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {
  Grid,
  Cell,
  Card,
  CardTitle,
  CardText,
  LinearProgress,
} from 'react-md';

import loanService from './../../services/loan';
import { baseUrl } from './../../services/endpoints';

import DefaultLayout from './../../components/layouts/default';
import LineChart from './../../components/molecules/charts/lineChart';
import PieChart from './../../components/molecules/charts/pieChart';

import {optionsGET, session} from './../../services/options';
import {isNull} from 'util';

import scheduleService from '../../services/schedule';
import tutorService from '../../services/tutors';
import roomService from '../../services/rooms';

// Renovaciones, salas y Referencistas

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loan: {
        data: [],
        loading: false,
        total: 0,
      },
      devices: {
        lastMothDevices: [],
        data: [],
        loading: false,
        total: 0,
      },
      academics: {
        data: [],
        loading: false,
        total: 0,
      },
      users: {
        data: [],
        loading: false,
        total: 0,
      },

      tutors: {
        data: [],
        loading: false,
        total: 0,
      },

      rooms: {
        data: [],
        loading: false,
        total: 0,
      },
    };

    this.getLoan = this.getLoan.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getLoanDevices = this.getLoanDevices.bind(this);
    this.getLoanAcademics = this.getLoanAcademics.bind(this);
  }

  componentDidMount() {
    if (isNull(session))
      window.location.reload();
    this.getLoan('month');
    this.getLoanDevices();
    this.getUsers('month');
    this.getLoanAcademics();
    this.getTutors();
    this.getRooms();
  }

  // =======================================================
  /// 
  // =======================================================
  getLoan(type) {
    this.setState({
      loan: {
        loading: true,
      },
    });

    function formatDate(date) {
      const startWithYear = /^[0-9]{4}/.test(date);
      const newDate = startWithYear ? date.slice(0, 10).split('-') : date.slice(0, 10).split('/');
      const hour = date.slice(11,);
      const day = startWithYear ? newDate[2] : newDate[0];
      const month = newDate[1];
      const year = startWithYear ? newDate[0] : newDate[2];

      //  console.log(date)
      //  console.log(`${day}-${month}-${year} / ${hour}`)

      return `${day}/${month}/${year} ${hour}`;
    }

    return loanService.generalReportsLoan({type}).then(response => {
      let map_ok = response
        .filter(({result}) => !/error/ig.test(result))
        .map(element => ({
          Préstamos: element.count,
          result: element.result,
          Fecha: formatDate(element.trace_date),
        }))
        .sort((prev, next) => {
          if (moment(prev.Fecha) < moment(next.Fecha)) return -1;
          if (moment(prev.Fecha) > moment(next.Fecha)) return 1;
          return 0;
        });

      this.setState({
        loan: {
          loading: false,
          data: map_ok,
          total: map_ok.reduce((total, element) => {
            return total + element.Préstamos;
          }, 0),
        },
      });
    })
    .catch(err => {
      alert('Hubo un error obteniendo la información de la base de datos.')
      console.log(`Unable to get the information from the database: ${err}`)
    })
  }

  // =======================================================
  /// 
  // =======================================================
  getUsers(type) {
    this.setState({
      users: {
        loading: true,
      },
    });
    //return loanService.generalReportsLoan({type})
    //return fetch(`http://52.14.65.118:4000/general_report_loans/?type=${type}&report=user`, optionsGET())
    return fetch(`${baseUrl}general_report_loans/?type=${type}&report=user`, optionsGET())
    .then(response => response.json())
      .then(response => {
        let data = response
          .filter(({result}) => !/error/ig.test(result))
          .sort((prev, next) => {
            if (moment(prev.trace_date) < moment(next.trace_date)) return -1;
            if (moment(prev.trace_date) > moment(next.trace_date)) return 1;
            return 0;
          });

        this.setState({
          users: {
            loading: false,
            data,
            total: data.reduce((total, element) => {
              return total + element.count;
            }, 0),
          },
        });
      })
      .catch(err => {
        alert('Hubo un error obteniendo la información de la base de datos.')
        console.log(`Unable to get the information from the database: ${err}`)
      })
  }

  // =======================================================
  /// 
  // =======================================================
  getLoanDevices() {
    this.setState({
      devices: {
        loading: true,
      },
    });
    /*
    return loanService.userTraces({
      operation: 'USER VALIDATE',
      limit: 500
    })
    */
    //return fetch('http://52.14.65.118:4000/user_traces/devices', optionsGET())
    return fetch(baseUrl + 'user_traces/devices', optionsGET())
      .then(response => response.json())
      .then(response => {
        let lastMothDevices = _.filter(response, device => moment(device.inserted_at).unix() >= moment().subtract(1, 'months').unix());

        lastMothDevices = lastMothDevices.reduce((acc, value, index, arr) => {
          _.filter(acc, (item, i) => item.so === value.so
            ? acc[i].push(value)
            : null);
          return acc;
        }, []);

        const devices = response.map(item => ({
          name: item.so || 'Desconocido',
          created: moment(item.inserted_at)
        })).reduce(function (arr, item) {
          let found = false;

          for (let i = 0; i < arr.length; i++) {
            if (arr[i].name === item.name) {
              found = true;
              arr[i].count++;
            }
          }

          if (!found) {
            item.count = 1;
            arr.push(item);
          }

          return arr;
        }, []);

        this.setState({
          devices: {
            lastMothDevices,
            loading: false,
            data: devices,
            total: devices.reduce((total, element) => {
              return total + element.count;
            }, 0),
          },
        });
      })
      .catch(err => {
        alert('Hubo un error obteniendo la información de la base de datos.')
        console.log(`Unable to get the information from the database: ${err}`)
      })
  }

  // =======================================================
  /// 
  // =======================================================
  getLoanAcademics() {
    this.setState({
      academics: {
        loading: true,
      },
    });
    /*
    return loanService.userTraces({
      operation: 'USER VALIDATE',
      limit: 500
    })
    */
    //return fetch('http://52.14.65.118:4000/user_traces/academics', optionsGET())
    return fetch(baseUrl + 'user_traces/academics', optionsGET())
      .then(response => response.json())
      .then(response => {
        /*
        let academics = [...new Set(response.map(item => ({name: item.department || 'Desconocido'})))]
          .map(({name}) => ({
            name,
            count: response.reduce((acc, el) => {
              if (el.name === name) acc++;
              return acc;
            }, 1)
          }));
        */

        let academics = response.map(item => ({
          name: item.department || 'Desconocido'
        })).reduce(function (arr, item) {
          let found = false;

          for (let i = 0; i < arr.length; i++) {
            if (arr[i].name === item.name) {
              found = true;
              arr[i].count++;
            }
          }

          if (!found) {
            item.count = 1;
            arr.push(item);
          }

          return arr;
        }, []);

        this.setState({
          academics: {
            loading: false,
            data: academics,
            total: academics.reduce((total, element) => {
              return total + element.count;
            }, 0),
          },
        });
      })
      .catch(err => {
        alert('Hubo un error obteniendo la información de la base de datos.')
        console.log(`Unable to get the information from the database: ${err}`)
      })
  }

  // =======================================================
  /// 
  // =======================================================
  getTutors = async () => {
    this.setState({
      tutors: {
        loading: true,
      },
    });

    const params = {
      type: 'month',
      reserved: 'tutor'
    }

    const respuesta = await tutorService.generalReportsTutors(params);

    const tutors = respuesta.map(element => ({
      Tutores: element.count,
      Fecha: element.trace_timestamp,
    }))
      .sort((prev, next) => {
        if (moment(prev.Fecha) < moment(next.Fecha)) return -1;
        if (moment(prev.Fecha) > moment(next.Fecha)) return 1;
        return 0;
      });

    this.setState({
      tutors: {
        loading: false,
        data: tutors,
        total: tutors.reduce((total, element) => {
          return total + element.Tutores;
        }, 0),
      },
    });
  }

  // =======================================================
  /// 
  // =======================================================
  getRooms = async () => {

    this.setState({
      rooms: {
        loading: true,
      },
    });

    const params = {
      type: 'month',
      reserved: 'room'
    }

    const respuesta = await roomService.generalReportsRoom(params);

    const rooms = respuesta.map(element => ({
      Salas: element.count,
      Fecha: element.trace_timestamp,
    }))
      .sort((prev, next) => {
        if (moment(prev.Fecha) < moment(next.Fecha)) return -1;
        if (moment(prev.Fecha) > moment(next.Fecha)) return 1;
        return 0;
      });

    this.setState({
      rooms: {
        loading: false,
        data: rooms,
        total: rooms.reduce((total, element) => {
          return total + element.Salas;
        }, 0),
      },
    });
  }

  render() {
    const {data} = this.state;

    let max = 0;
    let min = 0;
    let startDate = {};
    let endDate = {};

    if (data) {
      max = Math.max(..._.map(data, el => parseInt(el.Fecha.slice(0, 2))));
      min = Math.min(..._.map(data, el => parseInt(el.Fecha.slice(0, 2))));
      startDate = _.find(_.map(data, el => ({...el, id: parseInt(el.Fecha.slice(0, 2))})), {id: min});
      endDate = _.find(_.map(data, el => ({...el, id: parseInt(el.Fecha.slice(0, 2))})), {id: max});
    }

    return (
      <DefaultLayout>
        <Grid>
          {/* Préstamos */}
          <Cell size={6}>
            <Card style={styles.card}>
              <CardTitle title={this.state.loan.total} subtitle='Préstamos'/>
              <CardText>
                {this.state.loan.loading && (
                  <LinearProgress id='loanProgress'/>
                )}
                {this.state.loan.data && (
                  <LineChart
                    width={400}
                    height={300}
                    data={this.state.loan.data}
                    dataKeyX='Fecha'
                    dataKeyY='Préstamos'
                  />
                )}
              </CardText>
            </Card>
          </Cell>
          {/* Dispositivos */}
          <Cell size={6}>
            <Card style={styles.card}>
              <CardTitle title={this.state.devices.total} subtitle='Dispositivos'/>
              <CardText>
                {this.state.devices.loading && (
                  <LinearProgress id='devicesProgress'/>
                )}
                {this.state.devices.data && (
                  <PieChart
                    width={400}
                    height={300}
                    data={this.state.devices.data}
                    dataKey='count'
                  />
                )}

              </CardText>
            </Card>
          </Cell>
          {/* Usuarios */}
          <Cell size={6}>
            <Card style={styles.card}>
              <CardTitle title={this.state.users.total} subtitle='Usuarios'/>
              <CardText>
                {this.state.users.loading && (
                  <LinearProgress id='usersProgress'/>
                )}
                {this.state.users.data && (
                  <LineChart
                    width={400}
                    height={300}
                    data={this.state.users.data}
                    dataKeyX='trace_date'
                    dataKeyY='count'
                  />
                )}
              </CardText>
            </Card>
          </Cell>
          {/* Préstamos por programa académico */}
          <Cell size={6}>
            <Card style={styles.card}>
              <CardTitle title={this.state.academics.total} subtitle='Préstamos por programa académico'/>
              <CardText>
                {this.state.academics.loading && (
                  <LinearProgress id='academicsProgress'/>
                )}
                {this.state.academics.data && (
                  <PieChart
                    width={400}
                    height={300}
                    data={this.state.academics.data}
                    dataKey='count'
                  />
                )}

              </CardText>
            </Card>
          </Cell>

          {/* Tutores */}
          {this.props.tutorsBoolean && <Cell size={6}>
            <Card style={styles.card}>
              <CardTitle title={this.state.tutors.total} subtitle='Tutores'/>
              <CardText>
                {this.state.tutors.loading && (
                  <LinearProgress id='tutorsProgress'/>
                )}
                {this.state.tutors.data && (
                  <LineChart
                    width={400}
                    height={300}
                    data={this.state.tutors.data}
                    dataKeyX='Fecha'
                    dataKeyY='Tutores'
                  />
                )}
              </CardText>
            </Card>
          </Cell>}
          {/* Salas */}
          {this.props.roomsBoolean && <Cell size={6}>
            <Card style={styles.card}>
              <CardTitle title={this.state.rooms.total} subtitle='Salas'/>
              <CardText>
                {this.state.rooms.loading && (
                  <LinearProgress id='roomsProgress'/>
                )}
                {this.state.rooms.data && (
                  <LineChart
                    width={400}
                    height={300}
                    data={this.state.rooms.data}
                    dataKeyX='Fecha'
                    dataKeyY='Salas'
                  />
                )}

              </CardText>
            </Card>
          </Cell>}

        </Grid>
      </DefaultLayout>
    );
  }
}

const styles = {
  card: {
    borderRadius: '2px',
  },
};

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
});

export default connect(mapStateToProps)(Home);
