import {
  generalReportsLoanEndpoint,
  userTracesEndpoint
} from './endpoints';

import {optionsGET} from './options';


const loanService = {
  userTraces: (params) => fetch(userTracesEndpoint(params), optionsGET())
    .then((response) => {
      if (!response.ok) {
        return [];
      }
      return response.json();
    }),

  generalReportsLoan: (params, monthly) => fetch(generalReportsLoanEndpoint(params, monthly), optionsGET())
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    })
};

export default loanService;
