import React, {Component} from 'react';
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardTitle,
  CardText,
  Grid,
  TextField,
  LinearProgress,
  Button,
  FontIcon,
  SelectField,
} from 'react-md';

import userService from './../../services/users';

import DefaultLayout from './../../components/layouts/default';
import TableActions from './../../components/molecules/tableActions';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addUser: false,
      addUserLoading: false,
      systemUsers: null,
      systemUsersLoading: false,
    };

    this.getSystemUsers = this.getSystemUsers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteSystemUser = this.deleteSystemUser.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  componentDidMount() {
    this.getSystemUsers();
  }

  getSystemUsers() {
    this.setState({systemUsersLoading: true});
    return userService.getSystemUsers()
      .then(response => {
        this.setState({
          systemUsersLoading: false,
          systemUsers: response.map(item => ({
            id: item.id,
            Usuario: item.username,
            Nombre: item.first_name,
            Apellido: item.last_name,
            Email: item.email,
            Rol: item.role,
            Status: item.status,
          }))
        });
      })
      .catch(error => {
        this.setState({systemUsersLoading: false});
      });
  }

  handleChange(value, e) {
    this.setState({[e.target.name]: value});
  }

  handleSelectChange(value, index, event, details) {
    this.setState({[details.name]: value});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({addUserLoading: true});
    return userService.addSystemUser({
      username: this.state.addSystemUserUsername,
      status: 'active',
      role: this.state.addSystemUserRole,
      first_name: this.state.addSystemUserFirstName,
      last_name: this.state.addSystemUserLastName,
      email: this.state.addSystemUserEmail,
      password: this.state.addSystemUserPassword
    })
      .then(() => {
        this.setState({
          addUserLoading: false,
          addUser: false
        });
        this.clearForm();
        this.getSystemUsers();
      })
      .catch(error => {
        console.log(error);
        this.setState({addUserLoading: false});
      });
  }

  deleteSystemUser(item) {
    if (window.confirm(`¿Eliminar al usuario '${item.Usuario}?'`)) {
      userService.removeSystemUser(item.id)
        .then((response) => {
          this.getSystemUsers();
        })
        .catch(error => console.log(error));
    }
  }

  clearForm() {
    this.setState({
      addSystemUserUsername: null,
      addSystemUserRole: null,
      addSystemUserFirstName: null,
      addSystemUserLastName: null,
      addSystemUserEmail: null,
      addSystemUserPassword: null,
    });
  }

  render() {
    return (
      <DefaultLayout>
        <div className='buttons__group' style={{marginBottom: '2rem'}}>
          <Button onClick={() => this.setState({addUser: !this.state.addUser})} floating primary>
            <FontIcon>person_add</FontIcon>
          </Button>
        </div>
        {this.state.addUser && (
          <Card>
            <CardTitle title='Agregar usuario' />
            <CardText>
              <form onSubmit={this.handleSubmit}>
                <Grid>
                  <TextField
                    id='addSystemUserUsername'
                    name='addSystemUserUsername'
                    label='Nombre de usuario'
                    lineDirection='center'
                    placeholder='Ingrese el nombre de usuario'
                    className='md-cell md-cell--bottom'
                    value={this.state.addSystemUserUsername}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <TextField
                    id='addSystemUserFirstName'
                    name='addSystemUserFirstName'
                    label='Nombre'
                    lineDirection='center'
                    placeholder='Ingrese el nombre'
                    className='md-cell md-cell--bottom'
                    value={this.state.addSystemUserFirstName}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <TextField
                    id='addSystemUserLastName'
                    name='addSystemUserLastName'
                    label='Apellido'
                    lineDirection='center'
                    placeholder='Ingrese el apellido'
                    className='md-cell md-cell--bottom'
                    value={this.state.addSystemUserLastName}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <TextField
                    id='addSystemUserEmail'
                    name='addSystemUserEmail'
                    label='Email'
                    lineDirection='center'
                    placeholder='Ingrese la dirección de email'
                    className='md-cell md-cell--bottom'
                    value={this.state.addSystemUserEmail}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <TextField
                    id='addSystemUserPassword'
                    name='addSystemUserPassword'
                    label='Contraseña'
                    type='password'
                    passwordIcon={<FontIcon>remove_red_eye</FontIcon>}
                    className='md-cell md-cell--bottom'
                    value={this.state.addSystemUserPassword}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <SelectField
                    id='addSystemUserRole'
                    name='addSystemUserRole'
                    label='Rol'
                    placeholder='Seleccione rol de usuario'
                    className='md-cell md-cell--bottom'
                    menuItems={[
                      {
                        label: 'Usuario',
                        value: 'user'
                      },
                      {
                        label: 'Administrador',
                        value: 'admin'
                      },
                    ]}
                    value={this.state.addSystemUserRole}
                    onChange={this.handleSelectChange}
                    disabled={this.state.addUserLoading}
                  />
                  <Button
                    type='submit'
                    disabled={this.state.addUserLoading}
                    flat
                    primary
                    swapTheming
                  >
                    Agregar usuario
                  </Button>
                </Grid>
              </form>
            </CardText>
          </Card>
        )}
        <hr style={{marginTop: '2rem', marginBottom: '2rem'}} />
        <Card tableCard>
          <TableActions title='Usuarios del sistema' count={0} onResetClick={this.getSystemUsers} />
          {this.state.systemUsersLoading && <LinearProgress id='usersProgress' />}
          <DataTable baseId='dynamic-content-desserts'>
            {this.state.systemUsers && (
              <React.Fragment>
                <TableHeader>
                  <TableRow>
                    {Object.keys(this.state.systemUsers[0]).map((header, i) => (
                      <TableColumn key={header}>{header}</TableColumn>
                    ))}
                    <TableColumn key='actions'>Acciones</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {this.state.systemUsers.map((item, i) => (
                    <TableRow key={item.id}>
                      {Object.keys(item).map(key => (
                        <TableColumn key={key}>{item[key]}</TableColumn>
                      ))}
                      <TableColumn key='actions'>
                        <div className='buttons__group' style={{marginBottom: '2rem'}}>
                          <Button onClick={() => this.deleteSystemUser(item)} icon>
                            <FontIcon>delete</FontIcon>
                          </Button>
                        </div>
                      </TableColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </React.Fragment>
            )}
          </DataTable>
        </Card>
      </DefaultLayout>
    );
  }
}

export default Users;
