import React from 'react';
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import _ from 'lodash';
import moment from 'moment';

const barChart = (props) => {
  const data = _.map(props.data, newData => {
    //  console.log(/string/ig.test(typeof newData.trace_date));
    return {
      ...newData,
      trace_date: (/string/ig.test(typeof newData.trace_date) && newData.trace_date.length) - 1 === 19
        ? newData.trace_date
        : moment(newData.trace_date).format('DD-MM-YYYY')
    };
  });
  // console.log(props);
  return (

    <BarChart
      width={props.width || 400}
      height={props.height || 200}
      data={data}
      margin={{top: 10, right: 30, left: 20, bottom: 5}}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.dataKeyX}/>
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey={props.dataKeyY} fill={props.barColor} />
      { props.showTwoBars && ( 
        <Bar dataKey={props.dataKeyY2} fill={props.barColor2} /> 
      ) }
      
    </BarChart>

  );
};

export default barChart;
