import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import moment from 'moment';
import Workbook from "react-excel-workbook";

import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardTitle,
  CardText,
  LinearProgress,
  TablePagination,
  Grid,
  Cell, Button, DatePicker,
} from 'react-md';

import { baseUrl } from '../../../services/endpoints';

import PieChart from '../../../components/molecules/charts/pieChart';
import TableActions from '../../../components/molecules/tableActions';
import {optionsGET} from "../../../services/options";
import FlatOrIconButton from "../../../components/atoms/flatOrIconButton";

const DeviceReport = () => {


  const [loading, setLoading] = useState(false);
  const [devices_loading, setDevicesLoading] = useState(false);
  const [start_date, setStartDate] = useState(moment());
  const [end_date, setEndDate] = useState(moment());
  const [state, setState] = useState({
    excelData: [],
    data: [],
    operation: '',
    slicedData: [],
    devices: {
      data: [],
      total: '0',
    }
  });

  // =======================================================
  /// 
  // =======================================================
  const handlePagination = (start, rowsPerPage) => {
    setState({
      slicedData: state.data.slice(start, start + rowsPerPage)
    });
  };

  // =======================================================
  /// 
  // =======================================================
  const formatDate = (date) => {
    const startWithYear = /^[0-9]{4}/.test(date);
    const newDate = startWithYear ? date.slice(0, 10).split('-') : date.slice(0, 10).split('/');
    const hour = date.slice(11);
    const day = startWithYear ? newDate[2] : newDate[0];
    const month = newDate[1];
    const year = startWithYear ? newDate[0] : newDate[2];

    return `${day}/${month}/${year} ${hour}`;
  }

  // =======================================================
  /// 
  // =======================================================
  const getData = async () => {
    setLoading(true);
    setDevicesLoading(true);
    setState(prev => ({
      ...prev,
      data: [],
      slicedData: [],
    }));

    //const resp = await fetch('http://52.14.65.118:4000/user_traces/devices', optionsGET());
    const params = `?start_at=${moment(start_date).format('YYYY-MM-DD')}&end_at=${moment(end_date).format('YYYY-MM-DD')}`;
    const resp = await fetch(`${baseUrl}user_traces/devices${params}`, optionsGET());

    const response = resp.ok ? await resp.json() : [];

    // console.log("response", response);

    const excelData = response.map(({ so, device, username, inserted_at}) => ({
      username,
      device: (so || device) ? `${so ? so : ' '}${device ? device : ''}` : 'Sin información',
      inserted_at: moment(inserted_at).format('DD/MM/YYYY HH:mm')
    }));

    const data = response.map(element => {
      let ref = {
        'Usuario': '',
        'Dispositivo': ''
      };

      if (element.username) ref['Usuario'] = element.username;
      if (element.so) ref['Dispositivo'] = (element.so || element.device) ? `${element.so ? element.so : ' '}${element.device ? element.device : ''}` : 'Sin información';
      if (element.operation) ref['Operación'] = element.operation;
      if (element.result) ref['Resultado'] = element.result;
      if (element.data) ref['Información'] = element.data;
      if (element.inserted_at) ref['Fecha'] = formatDate(element.inserted_at);

      return ref;
    });

    const devices = response
      .map(item => ({name: item.so || 'Desconocido'}))
      .reduce((arr, item) => {
        let found = false;

        for (let i = 0; i < arr.length; i++) {
          if (arr[i].name === item.name) {
            found = true;
            arr[i].count++;
          }
        }

        if (!found) {
          item.count = 1;
          arr.push(item);
        }

        return arr;
      }, []);

    setLoading(false);
    setDevicesLoading(false);
    setState(prev => ({
      ...prev,
      excelData,
      data,
      slicedData: data.slice(0, 10),
      devices: {
        data: devices,
        total: devices.reduce((total, element) => {
          return total + element.count;
        }, 0),
      },
    }));
  }

  // =======================================================
  /// 
  // =======================================================
  useEffect(() => {
    getData();
  }, []);

  let devices = {};
  let total = 0;
  let data = [];

  if (state.devices) devices = state.devices;
  if (state.devices.data) data = state.devices.data;
  if (state.devices.total) total = state.devices.total;

  return (
    <Grid style={{width: '100%'}}>
      <Cell size={12}>
        <Card style={styles.card}>
          <CardTitle title={total} subtitle='Dispositivos' />
          <CardText>
            
            {devices_loading && (<LinearProgress id='devicesProgress' />)}

            {data && (
              <Grid>
                <Cell size={12}>
                  <PieChart
                    width={400}
                    height={220}
                    data={state.devices.data}
                    dataKey='count'
                  />
                </Cell>
              </Grid>
            )}

          </CardText>
        </Card>
        <Card tableCard>
          <TableActions title='Préstamos por Dispositivos' count={0} onResetClick={getData} />

          <Grid>
              <DatePicker
                id='date-picker-auto-ok'
                label='Desde'
                autoOk
                className='md-cell md-cell--bottom'
                name="historicDataStartAt"
                value={state.historicDataStartAt}
                onChange={(value, date) =>
                  setStartDate(moment(date).format('YYYY-MM-DD'))
                }
                locales='es-ES'
              />
              <DatePicker
                id='date-picker-auto-ok'
                label='Hasta'
                autoOk
                className='md-cell md-cell--bottom'
                name="historicDataEndAt"
                value={state.historicDataEndAt}
                onChange={(value, date) =>
                  setEndDate(moment(date).format('YYYY-MM-DD'))
                }
                locales='es-ES'
              />
              <Button
                type='submit'
                className='md-cell md-cell--bottom'
                onClick={getData}
                primary
                flat
              >
                Buscar
            </Button>
          </Grid>

          <Workbook
            filename='Préstamos por Dispositivos.xlsx'
            element={<Button
              style={{ marginLeft: 20 }}
              raised
              primary
              iconEl={<FlatOrIconButton
                iconChildren='folder'
                style={{ color: '#fff' }}>{loading ? 'Cargando...' : '»Exportar'}</FlatOrIconButton>} />}>

            <Workbook.Sheet
              data={state.excelData}
              name='Reportes'>
              <Workbook.Column
                label='Usuario'
                value='username' />
              <Workbook.Column
                label='Dispistivo'
                value='device' />
              <Workbook.Column
                label='Fecha'
                value='inserted_at' />
            </Workbook.Sheet>
          </Workbook>

          {loading && <LinearProgress id='usersProgress' />}
          
          <DataTable baseId='dynamic-content-desserts'>

            {state.data.length == 0 && (
              <tbody>
                <tr>
                  <td style={{textAlign: 'center', padding: '10px'}}>Sin Registro</td>
                </tr>
              </tbody>
            )}

            {state.slicedData.length > 0 && (
              <React.Fragment>
                <TableHeader>
                  <TableRow>
                    {Object.keys(state.slicedData[0]).map((header, i) => (
                      <TableColumn key={header}>{header}</TableColumn>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {state.slicedData.map((item, i) => (
                    <TableRow key={`item_table${i}`}>
                      {Object.keys(item).map(key => (
                        <TableColumn key={key}>{item[key]}</TableColumn>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
                <TablePagination
                  rows={state.data.length}
                  rowsPerPageLabel='Registros por página'
                  onPagination={handlePagination}
                />
              </React.Fragment>
            )}
          </DataTable>
        </Card>
      </Cell>
    </Grid>
  );
}

const styles = {
  card: {
    borderRadius: '2px',
    marginBottom: '2rem',
  },
};


export default DeviceReport;
