import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import _ from 'lodash';
import moment from 'moment';

const lineChart = (props) => {
  const data = _.map(props.data, newData => {
    //  console.log(/string/ig.test(typeof newData.trace_date));
    return {
      ...newData,
      trace_date: (/string/ig.test(typeof newData.trace_date) && newData.trace_date.length) - 1 === 19
        ? newData.trace_date
        : moment(newData.trace_date).format('DD-MM-YYYY')
    };
  });
  // console.log(props);
  return (
    <LineChart
      width={props.width || 400}
      height={props.height || 200}
      data={data}
      margin={{top: 10, right: 30, left: 20, bottom: 5}}
    >
      <XAxis dataKey={props.dataKeyX}/>
      <YAxis/>
      <CartesianGrid strokeDasharray="3 3"/>
      <Tooltip/>
      <Legend/>
      <Line
        type="monotone"
        dataKey={props.dataKeyY}
        stroke={props.lineColor}
        activeDot={{r: 8}}
      />
      {
        props.showTwoLines && (
          <Line
            type="monotone"
            dataKey={props.dataKeyY2}
            stroke={props.lineColor2}
            activeDot={{r: 8}}
          />
        )
      }
    </LineChart>
  );
};

export default lineChart;
