import React from 'react'
import PropTypes from 'prop-types'
import { TableCardHeader } from 'react-md'

import FlatOrIconButton from './../atoms/flatOrIconButton'

const TableActions = ({ count, onResetClick, title }) => (
  <TableCardHeader
    title={title}
    visible={count > 0}
    contextualTitle={`${count} item${count > 1 ? 's' : ''} listados`}
  >
    <FlatOrIconButton onClick={onResetClick} iconChildren="refresh">
      Actualizar
    </FlatOrIconButton>
  </TableCardHeader>
)

TableActions.propTypes = {
  count: PropTypes.number.isRequired,
  onResetClick: PropTypes.func,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
}

export default TableActions
