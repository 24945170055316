import {createStore, applyMiddleware, combineReducers} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import userReducer from './reducers/user';

const rootReducer = combineReducers({
  user: userReducer
});

const middlewares = [
  thunk
];

const store = createStore(
  rootReducer,
  //  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
