import React, { Component } from 'react';
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardTitle,
  CardText,
  Grid,
  TextField,
  LinearProgress,
  Button,
  FontIcon,
  SelectField,
  SelectionControl,
} from 'react-md';

import roomService from './../../services/rooms';

import DefaultLayout from './../../components/layouts/default';
import TableActions from './../../components/molecules/tableActions';

class Rooms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addUser: false,
      addUserLoading: false,
      systemUsers: null,
      isEditing: false,
      systemUsersLoading: false,
      addRoomVisibility: false,
      listTitles: [],
    };

    this.getSystemUsers = this.getSystemUsers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteSystemUser = this.deleteSystemUser.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.setForEdit = this.setForEdit.bind(this);
  }

  componentDidMount() {
    this.getSystemUsers();
  }

  getSystemUsers() {
    this.setState({ systemUsersLoading: true });
    return roomService.getRooms()
      .then(response => {

        let listItems = response.map(item => ({
          id: item.id,
          Locacion: item.location,
          Nombre: item.name,
          Capacidad: item.capacity,
          Descripcion: item.description,
          status: item.status,
        }));
        let listTitles = Object.keys(listItems[0]).filter( key => key !== 'status' );

        this.setState({
          systemUsersLoading: false,
          systemUsers: listItems,
          listTitles
        });

      })
      .catch(error => {
        this.setState({ systemUsersLoading: false });
      });
  }

  handleChange(value, e) {
    this.setState({ [e.target.name]: value });
  }


  handleSelectChange(value, index, event, details) {
    this.setState({ [details.name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ addUserLoading: true });
    if (this.state.isEditing) {
      return roomService.editRoom({
        id: this.state.addRoomId,
        name: this.state.addRoomName,
        location: this.state.addRoomLocation,
        description: this.state.addRoomDescription,
        capacity: this.state.addRoomCapacity,
        status: this.state.addRoomVisibility ? 1 : 0,
      })
        .then(() => {
          this.setState({
            addUserLoading: false,
            addUser: false,
            isEditing: false,
            addRoomId: null
          });
          this.clearForm();
          this.getSystemUsers();
        })
        .catch(error => {
          console.error(error);
          this.setState({ addUserLoading: false });
        });
    } else {
      return roomService.addRoom({
        name: this.state.addRoomName,
        location: this.state.addRoomLocation,
        description: this.state.addRoomDescription,
        capacity: this.state.addRoomCapacity,
        status: this.state.addRoomVisibility ? 1 : 0,
      })
        .then(() => {
          this.setState({
            addUserLoading: false,
            addUser: false
          });
          this.clearForm();
          this.getSystemUsers();
        })
        .catch(error => {
          console.error(error);
          this.setState({ addUserLoading: false });
        });
    }
  }

  deleteSystemUser(item) {
    if (window.confirm(`¿Eliminar al usuario '${item.Usuario}?'`)) {
      roomService.removeSystemUser(item.id)
        .then((response) => {
          this.getSystemUsers();
        })
        .catch(error => console.error(error));
    }
  }

  setForEdit(item) {
    this.setState({
      isEditing: true,
      addUser: true,
      addRoomId: item.id,
      addRoomName: item.Nombre,
      addRoomLocation: item.Locacion,
      addRoomCapacity: item.Capacidad,
      addRoomDescription: item.Descripcion,
      addRoomVisibility: item.status == 1,
    })
  }

  // =======================================================
  /// Cambio de estado de una sala
  // =======================================================
  changeVisibility = (item) => {
    this.setState({ addUserLoading: true });

    return roomService.editRoom({
      id: item.id,
      name: item.Nombre,
      location: item.Locacion,
      description: item.Descripcion,
      capacity: item.Capacidad,
      status: item.status ? 0 : 1, // envio valor inverso
    })
      .then(() => {
        this.getSystemUsers();
        this.setState({ addUserLoading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ addUserLoading: false });
      });
  }


  clearForm() {
    this.setState({
      addRoomName: null,
      addRoomLocation: null,
      addRoomCapacity: null,
      addRoomDescription: null,
      addRoomVisibility: false,
    });
  }

  render() {

    return (
      <DefaultLayout>
        <div className='buttons__group' style={{ marginBottom: '2rem' }}>
          <Button onClick={() => this.setState({ addUser: !this.state.addUser })} floating primary>
            <FontIcon>person_add</FontIcon>
          </Button>
        </div>
        {this.state.addUser && (
          <Card>
            <CardTitle title={`${this.state.isEditing ? "Editar" : "Agregar"} Sala`} />
            <CardText>
              <form onSubmit={this.handleSubmit}>
                <Grid>
                  <TextField
                    id='addRoomName'
                    name='addRoomName'
                    label='Nombre de la Sala'
                    lineDirection='center'
                    placeholder='Ingrese el nombre o número de la sala'
                    className='md-cell md-cell--bottom'
                    value={this.state.addRoomName}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <TextField
                    id='addRoomLocation'
                    name='addRoomLocation'
                    label='Locación de la Sala'
                    lineDirection='center'
                    placeholder='Ingrese el nombre del edificio'
                    className='md-cell md-cell--bottom'
                    value={this.state.addRoomLocation}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <TextField
                    id='addRoomDescription'
                    name='addRoomDescription'
                    label='Descripción'
                    lineDirection='center'
                    placeholder='Ingrese una descripción del mobilirio (tablero, borrador, proyector...)'
                    className='md-cell md-cell--bottom'
                    value={this.state.addRoomDescription}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <TextField
                    id='addRoomCapacity'
                    name='addRoomCapacity'
                    type="number"
                    label='Capacidad'
                    lineDirection='center'
                    placeholder='Capacidad de personas en número'
                    className='md-cell md-cell--bottom'
                    value={this.state.addRoomCapacity}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <SelectionControl
                    id="addRoomVisibility"
                    name="addRoomVisibility"
                    type="switch"
                    label="habilitar"
                    className='md-cell md-cell--bottom'
                    checked={this.state.addRoomVisibility}
                    value={this.state.addRoomVisibility}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <Button
                    type='submit'
                    disabled={this.state.addUserLoading}
                    flat
                    className='md-cell md-cell--bottom'
                    primary
                    swapTheming
                  >
                    {`${this.state.isEditing ? "Editar" : "Agregar"} Sala`}
                  </Button>
                </Grid>
              </form>
            </CardText>
          </Card>
        )}
        <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
        <Card tableCard>
          <TableActions title='Salas' count={0} onResetClick={this.getSystemUsers} />
          {this.state.systemUsersLoading && <LinearProgress id='usersProgress' />}
          <DataTable baseId='dynamic-content-desserts'>
            {this.state.systemUsers && (
              <React.Fragment>

                <TableHeader>
                  <TableRow>
                    {this.state.listTitles.map((header, i) => (
                      <TableColumn key={header}>{header}</TableColumn>
                    ))}
                    <TableColumn key='actions'>Acciones</TableColumn>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {this.state.systemUsers.map((item, i) => (
                    <TableRow key={item.id}>
                      {
                        this.state.listTitles.map(key => (
                          <TableColumn key={key}>{item[key]}</TableColumn>
                        ))
                      }
                      <TableColumn key='actions'>
                        <div className='buttons__group' style={{ marginBottom: '2rem' }}>
                          <Button onClick={() => this.changeVisibility(item)} icon>
                            <FontIcon>{item.status ? 'visibility' : 'visibility_off'}</FontIcon>
                          </Button>
                          <Button onClick={() => this.setForEdit(item)} icon>
                            <FontIcon>edit</FontIcon>
                          </Button>
                          <Button disabled onClick={() => this.deleteSystemUser(item)} icon>
                            <FontIcon>delete</FontIcon>
                          </Button>
                        </div>
                      </TableColumn>
                    </TableRow>
                  ))}
                </TableBody>

              </React.Fragment>
            )}
          </DataTable>
        </Card>
      </DefaultLayout>
    );
  }
}

export default Rooms;
