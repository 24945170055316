import React, { useState, useEffect } from 'react';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import LoginPage from './pages/login';
import HomePage from './pages/home/index';
import UsersPage from './pages/home/users';
import ReservationPage from './pages/home/reservation';
import NewReportsPage from './pages/home/reports';
import AuditPage from './pages/home/audit/index';
import ProfilePage from './pages/home/profile';
import SettingsPage from './pages/home/settings';
import Rooms from './pages/home/rooms';
import Tutors from './pages/home/tutors';
import ScheduleTutors from './pages/home/scheduleTutors';
import ScheduleRooms from './pages/home/scheduleRooms';

import { appTypeRequest } from './store/reducers/user';

const App = (props) => {

  const [routesAdded, setRoutesAdded] = useState([]);
  const [roomsBoolean, setRoomsBoolean] = useState(false);
  const [tutorsBoolean, setTutorsBoolean] = useState(false);

  useEffect(() => {
    if (props.loggedIn === true) {
      props.appTypeRequest();
    }
  }, []);

  useEffect(() => {
    if (props.appType !== undefined) {
      if (props.appType !== null) {
        let itemsAdded = [...routesAdded];

        props.appType.map(item => {
          switch (item) {
            case 'loan': itemsAdded = itemsAdded.concat([
                {path: '/audit', component: AuditPage},
              ]);
              break;

            case 'rooms':
              if (!itemsAdded.some(item => {return item.path === '/reservation'})) {
                itemsAdded = itemsAdded.concat([
                  {path: '/reservation', component: ReservationPage},
                ]);
              }
              itemsAdded = itemsAdded.concat([
                {path: '/rooms', component: Rooms},
                {path: '/rooms/schedule', component: ScheduleRooms},
              ]);
              setRoomsBoolean(true);
              break;

            case 'tutors':
              if (!itemsAdded.some(item => {return item.path === '/reservation'})) {
                itemsAdded = itemsAdded.concat([
                  {path: '/reservation', component: ReservationPage},
                ]);
              }
              itemsAdded = itemsAdded.concat([
                {path: '/tutors', component: Tutors},
                {path: '/tutors/schedule', component: ScheduleTutors},
              ]);
              setTutorsBoolean(true);
              break;

            default: console.log('No se encontró a qué item del navList pertenece el módulo...');
          }
        });
        
        setRoutesAdded(itemsAdded);
      }
    }
  }, [props.appType]);

  const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
      {...rest}
      render={localProps => (
        props.loggedIn === true ? <Component {...localProps} roomsBoolean={roomsBoolean} tutorsBoolean={tutorsBoolean}/> : <Redirect to='/login'/>
      )}
    />
  );

  const GuestRoute = ({component: Component, ...rest}) => (
    <Route
      {...rest}
      render={localProps => (
        props.loggedIn === false ? <Component {...localProps} /> : <Redirect to='/'/>
      )}
    />
  );

  return (
    <main>
      {/* <Switch>
        <GuestRoute exact path="/login" component={LoginPage}/>
        <PrivateRoute exact path="/" component={HomePage}/>
        <PrivateRoute exact path="/reservation" component={ReservationPage}/>
        <PrivateRoute exact path="/users" component={UsersPage}/>
        <PrivateRoute exact path="/reports" component={NewReportsPage}/>
        <PrivateRoute exact path="/rooms" component={Rooms}/>
        <PrivateRoute exact path="/rooms/schedule" component={ScheduleRooms}/>
        <PrivateRoute exact path="/tutors" component={Tutors}/>
        <PrivateRoute exact path="/tutors/schedule" component={ScheduleTutors}/>
        <PrivateRoute exact path="/audit" component={AuditPage}/>
        <PrivateRoute exact path="/settings" component={SettingsPage}/>
        <PrivateRoute exact patch="/profile" component={ProfilePage}/>
      </Switch> */}
      <Switch>
        <GuestRoute exact path="/login" component={LoginPage}/>
        <PrivateRoute exact path="/" component={HomePage}/>
        <PrivateRoute exact path="/users" component={UsersPage}/>
        <PrivateRoute exact path="/reports" component={NewReportsPage}/>
        {routesAdded.length !== 0 && routesAdded.map((item, i) => {
          return (
            <PrivateRoute exact path={item.path} component={item.component} key={i}/>
          );
        })}
        <PrivateRoute exact path="/settings" component={SettingsPage}/>
        <PrivateRoute exact patch="/profile" component={ProfilePage}/>
      </Switch>
    </main>
  );
};

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  appType: state.user.appType,
});

const mapDispatchToProps = dispatch => ({
  appTypeRequest: () => dispatch(appTypeRequest()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
