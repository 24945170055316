import React, { Component } from 'react'
import {
  Card,
  CardTitle,
  CardText,
  Grid,
  TextField,
  LinearProgress,
  Button,
  FontIcon,
  SelectField,
} from 'react-md'

import userService from './../../services/users'

import DefaultLayout from './../../components/layouts/default'

class Profile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      userLoading: false,
    }

    this.getSystemUser = this.getSystemUser.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.getSystemUser()
  }

  getSystemUser() {
    this.setState({ userLoading: true })
    return userService.getSystemUserProfile()
      .then(response => {
        this.setState({
          userLoading: false,
          user: true,
          systemUser: response.id,
          systemUserUsername: response.username,
          systemUserFirstName: response.first_name,
          systemUserLastName: response.last_name,
          systemUserEmail: response.email,
          systemUserRole: response.role,
          systemUserStatus: response.status,
        })
      })
      .catch(error => {
        this.setState({ userLoading: false })
      })
  }

  handleChange(value, e) {
    this.setState({ [e.target.name]: value })
  }

  handleSelectChange(value, index, event, details) {
    this.setState({ [details.name]: value })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ userLoading: true })
    return userService.updateSystemUserProfile({
      username: this.state.systemUserUsername,
      status: 'active',
      role: this.state.systemUserRole,
      first_name: this.state.systemUserFirstName,
      last_name: this.state.systemUserLastName,
      email: this.state.systemUserEmail,
      password: this.state.systemUserPassword
    })
      .then(() => {
        this.setState({
          userLoading: false,
          addUser: false
        })
        this.getSystemUser()
      })
      .catch(error =>{
        console.log(error)
        this.setState({ userLoading: false })
      })
  }

  render() {
    return (
      <DefaultLayout>
        <Card>
          <CardTitle title="Mi perfil" />
          <CardText>
            {this.state.userLoading && (
              <LinearProgress id="devicesProgress" />
            )}
            {!this.state.userLoading && this.state.user && (
              <form onSubmit={this.handleSubmit}>
                <Grid>
                  <TextField
                    id="systemUserUsername"
                    name="systemUserUsername"
                    label="Nombre de usuario"
                    lineDirection="center"
                    placeholder="Ingrese el nombre de usuario"
                    className="md-cell md-cell--bottom"
                    value={this.state.systemUserUsername}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <TextField
                    id="systemUserFirstName"
                    name="systemUserFirstName"
                    label="Nombre"
                    lineDirection="center"
                    placeholder="Ingrese el nombre"
                    className="md-cell md-cell--bottom"
                    value={this.state.systemUserFirstName}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <TextField
                    id="systemUserLastName"
                    name="systemUserLastName"
                    label="Apellido"
                    lineDirection="center"
                    placeholder="Ingrese el apellido"
                    className="md-cell md-cell--bottom"
                    value={this.state.systemUserLastName}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <TextField
                    id="systemUserEmail"
                    name="systemUserEmail"
                    label="Email"
                    lineDirection="center"
                    placeholder="Ingrese la dirección de email"
                    className="md-cell md-cell--bottom"
                    value={this.state.systemUserEmail}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <TextField
                    id="systemUserPassword"
                    name="systemUserPassword"
                    label="Contraseña"
                    type="password"
                    passwordIcon={<FontIcon>remove_red_eye</FontIcon>}
                    className="md-cell md-cell--bottom"
                    value={this.state.systemUserPassword}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <SelectField
                    id="systemUserRole"
                    name="systemUserRole"
                    label="Rol"
                    placeholder="Seleccione rol de usuario"
                    className="md-cell md-cell--bottom"
                    menuItems={[
                      {
                        label: 'Usuario',
                        value: 'user'
                      },
                      {
                        label: 'Administrador',
                        value: 'admin'
                      },
                    ]}
                    value={this.state.systemUserRole}
                    onChange={this.handleSelectChange}
                    disabled={this.state.addUserLoading}
                  />
                  <Button
                    type="submit"
                    disabled={this.state.addUserLoading}
                    flat
                    primary
                    swapTheming
                  >
                    Actualizar información
                  </Button>
                </Grid>
              </form>
            )}
          </CardText>
        </Card>
      </DefaultLayout>
    )
  }
}

export default Profile
