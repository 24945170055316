import React, { useState, useEffect } from 'react';
import {
  TimePicker,
  DatePicker,
  Card,
  CardTitle,
  CardText,
  Grid,
  Checkbox,
  Button,
  FontIcon,
  SelectField,
  LinearProgress,
} from 'react-md';
import moment from 'moment';

import scheduleService from '../../services/schedule';
import roomService from "../../services/rooms"

import DefaultLayout from '../../components/layouts/default';

const ScheduleRooms = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [multipleDays, setMultipleDays] = useState(false);
  const [roomsList, setRoomList] = useState([]);
  const [inputs, setInputs] = useState({
    addTutorId: '',
    addUser: '',
    addTutorStartDate: '',
    addTutorEndDate: '',
    addTutorStartTime: '',
    addTutorEndTime: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const resp = await roomService.getRooms();

      const lista = resp.map(item => ({
        value: item.id,
        label: item.name,
      }));

      setRoomList(lista);
      setIsLoading(false);
    }

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    let list_events = [];
    console.log("inputs", inputs);
    const difference = inputs.addTutorEndDate ? moment(inputs.addTutorEndDate).diff(inputs.addTutorStartDate, 'day') : 0;

    console.log("diferencia", difference);

    for (let index = 0; index <= difference; index++) {
      console.log("index", index);
      const element = moment(inputs.addTutorStartDate).add(index, 'day');
      
      list_events.push({
        "type": "room",
        "scheduled_id": inputs.addTutorId,
        "start_at": getDateStart(element, inputs.addTutorStartTime),
        "end_at": getDateFinal(element, inputs.addTutorEndTime),
        "status": "0"
      });

    }

    console.log("list_events", list_events);

    const resp = await scheduleService.addListSchedules({array:list_events});

    setIsLoading(false);

    if(resp.success) {
      alert('Se ha generado correctamente');
      clearForm();
    }else {
      alert(resp.message);
    }

    console.log(resp);

  }

  const getDateStart = (date, time) => {
    const fecha = moment(date).format('YYYY-MM-DD');
    const hora = moment(time).format('HH:mm:00');
    return moment(`${fecha} ${hora}`).format('YYYY-MM-DD HH:mm:ss');
  };

  const getDateFinal = (date, time) => {
    const fecha = moment(date).format('YYYY-MM-DD');
    const hora = moment(time).subtract(1, 'second').format('HH:mm:00');
    return moment(`${fecha} ${hora}`).format('YYYY-MM-DD HH:mm:ss');
  };

  const clearForm = () => setInputs({
    addTutorId: '',
    addUser: '',
    addTutorStartDate: '',
    addTutorEndDate: '',
    addTutorStartTime: '',
    addTutorEndTime: ''
  });


  const handleSelectChange = (name, value) => {
    setInputs(prev => ({...prev, [name]: value}));
  }
  
  return (
    <DefaultLayout>
      <Card>
        <CardTitle title='Agregar Sala' />
        {isLoading && (<LinearProgress id='usersProgress' />)}
        <CardText>
          <form onSubmit={handleSubmit}>
            <Grid>
              <SelectField
                required
                id='addTutorId'
                label='Sala'
                placeholder='Seleccione Sala'
                className='md-cell md-cell--bottom'
                menuItems={roomsList}
                value={inputs.addTutorId}
                onChange={(value) => handleSelectChange('addTutorId', value)}
                disabled={isLoading}
              />

              <DatePicker
                required
                id="date_addTutorStartDate"
                label={`Fecha ${multipleDays ? "inicial" : ""}`}
                name="addTutorStartDate"
                className="md-cell"
                displayMode="portrait"
                value={inputs.addTutorStartDate}
                onChange={(value, val) => handleSelectChange('addTutorStartDate', val)}
                disabled={isLoading}
              />
              {
                multipleDays
                  ? (
                    <DatePicker
                      required
                      id="date_addTutorEndDate"
                      label="Fecha final"
                      name="addTutorEndDate"
                      className="md-cell"
                      displayMode="portrait"
                      value={inputs.addTutorEndDate}
                      minDate={inputs.addTutorStartDate}
                      onChange={(value, val) => handleSelectChange('addTutorEndDate', val)}
                      disabled={isLoading}
                    />
                  ) : (
                    <Checkbox
                      id="checkbox-read-material-design-spec"
                      name="simple-checkboxes"
                      label="Múltiples días para este horario"
                      onClick={() => setMultipleDays(true)}
                    />
                  )
              }
              <TimePicker
                required
                id="time_addTutorStartTime"
                label="Hora inicio"
                className="md-cell"
                defaultValue={new Date('August 19, 1975 23:00:00')}
                displayMode="portrait"
                name="addTutorStartTime"
                value={inputs.addTutorStartTime}
                onChange={(value, val) => handleSelectChange('addTutorStartTime', val)}
                disabled={isLoading}
              />

              <TimePicker
                required
                id="time_addTutorEndTime"
                label="Hora fin"
                className="md-cell"
                defaultValue={new Date('August 19, 1975 23:00:00')}
                displayMode="portrait"
                name="addTutorEndTime"
                value={inputs.addTutorEndTime}
                onChange={(value, val) => handleSelectChange('addTutorEndTime', val)}
                disabled={isLoading}
              />
              <Button
                type='submit'
                disabled={isLoading}
                flat
                className='md-cell md-cell--bottom'
                primary
                swapTheming
              >
                Agregar Horario Sala
                  </Button>
            </Grid>
          </form>
        </CardText>
      </Card>
    </DefaultLayout>
  );
}

export default ScheduleRooms;
