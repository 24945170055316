import React, { useState } from 'react';

import { Grid } from 'react-md';

import DefaultLayout from '../../components/layouts/default';

// Components
import ProgramaAcademico from './reports/ProgramaAcademico';
import DeviceReport from './reports/devices_report';
import LoanReport from './reports/loan_report';
import ScheduleReport from './reports/schedule_report';

const NewReportsPage = (props) => {

    const [pageSel, setPageSel] = useState(1);

    return (
        <DefaultLayout>
            <ul className="md-tabs md-background--primary tabs-reports">
                <li
                    className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline md-tab ${pageSel === 1 ? "md-tab--active" : "md-tab--inactive"}`}
                    onClick={() => setPageSel(1)}
                >
                    <div className="md-ink-container"></div><div className="md-tab-label">Préstamos</div>
                </li>
                {(props.roomsBoolean && props.tutorsBoolean) &&
                    <li
                        className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline md-tab ${pageSel === 2 ? "md-tab--active" : "md-tab--inactive"}`}
                        onClick={() => setPageSel(2)}
                    >
                        <div className="md-ink-container"></div><div className="md-tab-label">Salas/Tutores</div>
                    </li>
                }
                {(props.roomsBoolean && !props.tutorsBoolean) &&
                    <li
                        className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline md-tab ${pageSel === 2 ? "md-tab--active" : "md-tab--inactive"}`}
                        onClick={() => setPageSel(2)}
                    >
                        <div className="md-ink-container"></div><div className="md-tab-label">Salas</div>
                    </li>
                }
                {(!props.roomsBoolean && props.tutorsBoolean) &&
                    <li
                        className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline md-tab ${pageSel === 2 ? "md-tab--active" : "md-tab--inactive"}`}
                        onClick={() => setPageSel(2)}
                    >
                        <div className="md-ink-container"></div><div className="md-tab-label">Tutores</div>
                    </li>
                }
                {(!props.roomsBoolean && !props.tutorsBoolean) &&
                    null
                }
                <li
                    className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline md-tab ${pageSel === 3 ? "md-tab--active" : "md-tab--inactive"}`}
                    onClick={() => setPageSel(3)}
                >
                    <div className="md-ink-container"></div><div className="md-tab-label">Dispositivos</div>
                </li>
                <li
                    className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline md-tab ${pageSel === 4 ? "md-tab--active" : "md-tab--inactive"}`}
                    onClick={() => setPageSel(4)}
                >
                    <div className="md-ink-container"></div><div className="md-tab-label">Programa académico</div>
                </li>
            </ul>

            <div className="md-tabs-content" style={{ overflowX: "hidden"}}>
                <Grid>

                    {
                        pageSel === 1 && (
                            <LoanReport />
                        )
                    }
                    
                    {
                        pageSel === 2 && (
                            <ScheduleReport roomsBoolean={props.roomsBoolean} tutorsBoolean={props.tutorsBoolean}/>
                        )
                    }

                    {
                        pageSel === 3 && (
                            <DeviceReport />
                        )
                    }

                    {
                        pageSel === 4 && (
                            <ProgramaAcademico />
                        )
                    }

                </Grid>
            </div>
        </DefaultLayout>
    );
};

export default NewReportsPage;