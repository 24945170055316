import React, { useEffect } from 'react';
import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import 'moment/locale/es';

import './style.css';

moment.locale('es');

const Schedule = ({ 
    onItemClick = () => { }, 
    groups = [], 
    items = [], 
    dayToShow = moment(), 
    titleTable, 
    groupInfo = { titleLabel: '', titleProp: '', subtiLabel: '', subtiProp: ''} 
}) => {

    // =======================================================
    /// 
    // =======================================================
    const stylesheetAttach = () => {
        const scheduleStyle = document.querySelector('#scheduleStyle');
        if (scheduleStyle === null) {
            const head = document.querySelector('head');
            const style = document.createElement('style');
            style.id = 'scheduleStyle';
            style.textContent = `
                * {
                    --my-var: 1px;
                }
            `;
            head.appendChild(style);
        }
    };

    // =======================================================
    /// 
    // =======================================================
    const groupRenderer = ({ group }) => {

        const tooltipId = `tooltip-content-${group.id}`;

        // console.log("group", group.group, group);

        return (
            <div className="group-title">
                <span className="title-sub">
                    {groupInfo.titleLabel} {group[groupInfo.titleProp]}
                    {
                        group.group == 'tutor' && <i className="material-icons icon-label"  data-tip data-for={tooltipId}>info_outline</i>
                    }
                </span>

                <p className="capacidad">
                    {
                        group.group == 'room' && <i className="material-icons icon-label"  data-tip data-for={tooltipId}>info_outline</i>
                    }
                    {groupInfo.subtiLabel} {group[groupInfo.subtiProp]}
                </p>

                <ReactTooltip id={tooltipId} place="right" effect="solid">
                    <div className="tooltip-content-group">
                        {tooltipContent(group)}
                    </div>
                </ReactTooltip>
            </div>
        );
    };

    // =======================================================
    /// Tooltip
    // =======================================================
    const tooltipContent = group => {
        
        let jsx = null;
        let imageTutor = "https://i.pinimg.com/564x/0d/36/e7/0d36e7a476b06333d9fe9960572b66b9.jpg";
        let imageRoom = "/images/reserva_de_salas.jpg";

        if (group.group === 'tutor') {
            jsx = <>
                <span>
                    <img style={{ width: 40, height: 40 }} src={imageTutor} />
                </span>
                <span style={{ margin: 'auto 15px' }}>
                    Tema: {group.tema}
                </span>
            </>;
        } else if (group.group === 'room') {
            jsx = <>
                <span>
                    <img style={{ width: 40, height: 40 }} src={imageRoom} />
                </span>
                <span style={{ margin: 'auto 15px' }}>
                    Descripción: {group.descripcion}
                </span>
            </>;
        }
        return jsx;
    };

    // =======================================================
    /// 
    // =======================================================
    const itemRenderer = ({ item, itemContext, getItemProps }) => {

        let backgroundColor = '#c32327';
        if (item.available) {
            backgroundColor = itemContext.selected ? '#fdc02f' : 'green';
        } else {
            backgroundColor = itemContext.selected ? '#c32328' : '#c32327';
        }
        return (
            <div
                {...getItemProps({
                    style: {
                        backgroundColor: 'transparent',
                        borderStyle: 'none',
                        zIndex: 5,
                    },
                    onMouseDown: () => {
                        onItemClick(item);
                    },
                })}
            >
                <div
                    style={{
                        backgroundColor,
                        borderColor: backgroundColor,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '85%',
                        margin: '0 auto',
                        alignItems: 'center',
                        height: 45,
                        marginTop: '-5px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <p style={{ textAlign: 'center', color: 'white', verticalAlign: 'center' }}>
                        {item.available ? 'Disp.' : 'Ocup.'}
                    </p>
                </div>
            </div>
        );
    };

    useEffect(() => {
        stylesheetAttach();
    }, []);


    return (
        <Timeline
            canMove={false}
            canResize={false}
            canChangeGroup={false}
            groups={groups}
            items={items}
            itemRenderer={itemRenderer}
            groupRenderer={groupRenderer}
            lineHeight={55}
            style={{ border: '#ddd solid 2px' }}
            visibleTimeStart={moment(dayToShow)
                .startOf('day')
                .add(6, 'hour')
                .valueOf()}
            visibleTimeEnd={moment(dayToShow)
                .endOf('day')
                .add(-2, 'hour')
                .valueOf()}
        >
            <TimelineHeaders>
                <SidebarHeader>
                    {({ getRootProps }) => {
                        return (
                            <div {...getRootProps({ style: { backgroundColor: '#003366', padding: 5 } })} >
                                {
                                    titleTable && (
                                        <p style={{ color: '#fff' }}>
                                            <strong>Reservas:</strong> <br />
                                            {titleTable}
                                        </p>
                                    )
                                }
                            </div>
                        );
                    }}
                </SidebarHeader>
                <DateHeader unit="primaryHeader" style={{ backgroundColor: '#003366' }} />
                <DateHeader />
            </TimelineHeaders>
        </Timeline>
    );
}

export default Schedule;