import React from 'react'
import { Toolbar } from 'react-md'

const TopNavigation = () =>
  <React.Fragment>
    <Toolbar
      zDepth={1}
      fixed
      colored
    >
      <div style={style.wrapper}>
        <div style={style.container}>
          <img src="/images/logo2.png" alt="Autopréstamo" />
          <span style={style.brand}>Autopréstamo</span>
        </div>
      </div>
    </Toolbar>
  </React.Fragment>

const style = {
  wrapper: {
    display: 'table',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'table-cell',
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  brand: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: '2rem',
    color: 'white',
    verticalAlign: 'bottom'
  }
}

export default TopNavigation
