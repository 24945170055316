import { sessionEndpoint, sessionAppTypeEndpoint } from './endpoints'

import { optionsGET, optionsPOST, optionsDELETE } from './options'

const AuthenticationService = {
  logIn: (credentials) => fetch(sessionEndpoint(), optionsPOST(credentials, false))
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    })
    .then((response) => {
      if (response.jwt && response.user) {
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.setItem('session', JSON.stringify({ jwt: response.jwt, exp: response.exp }));
      }
      return response
    }),

  logOut: () => fetch(sessionEndpoint(), optionsDELETE())
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    })
    .then((response) => {
      if (response.jwt === 'revoked') {
        localStorage.removeItem('user')
        localStorage.removeItem('session')
      }
      return response
    }),

  appType: () => fetch(sessionAppTypeEndpoint(), optionsGET())
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    })
    .then((response) => {
      return response;
    }),
}

export default AuthenticationService;
