import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
    Card,
    Cell,
    DatePicker,
    Grid,
    LinearProgress,
    CardText,
    DialogContainer,
    List,
    ListItem,
    Button,
} from 'react-md';

import TableActions from './../../components/molecules/tableActions';

import DefaultLayout from './../../components/layouts/default';
import roomService from './../../services/rooms';
import tutorService from './../../services/tutors';
import Schedule from '../../components/Schedule';

import "./styles.css";

const ReservationPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);

    const [groupsRooms, setGroupsRooms] = useState([]);
    const [itemsRooms, setItemsRooms] = useState([]);

    const [groupsTutors, setGroupsTutors] = useState([]);
    const [itemsTutors, setItemsTutors] = useState([]);

    const [pickerValue, setPickerValue] = useState(moment());

    const [itemSelected, setItemSelected] = useState([]);
    const [itemTime, setItemTime] = useState({});

    // =======================================================
    /// Consulto los datos de las salas
    // =======================================================
    const fetchRooms = async (day) => {
        setIsLoading(true);

        try {
            const resp_rooms = await roomService.getRooms();
            const resp_reservations = await roomService.getRoomsReservations(day);

            // Grupos
            const listGroupsRooms = resp_rooms.map(item => ({
                id: item.id,
                Locacion: item.location,
                title: item.name,
                Nombre: item.name,
                capacidad: item.capacity,
                descripcion: item.description,
                status: item.status,
                group: 'room',
            })).filter(item => item.status === 1);

            const filterList = resp_reservations.filter(item => {
                return item.status !== null;
            });

            // Lista de #items
            const listItemsRooms = filterList.map((item, index) => {

                let start_time = moment(item.started_at);
                let start_next_time = moment(item.ended_at);

                return {
                    id: index,
                    group: item.reserved_id,
                    available: item.status == '0',
                    start_time,
                    end_time: moment(item.ended_at),
                    title: `${start_time.format('HH:mm')} - ${start_next_time.add(1, 'second').format('HH:mm')}`,
                    user_id: item.user_id,
                    backend_id: item.id,
                    item
                };
            });

            setGroupsRooms(listGroupsRooms);
            setItemsRooms(listItemsRooms);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);

    };

    // =======================================================
    /// Consulta los datos de los tutores
    // =======================================================
    const fetchTutors = async (day) => {
        setIsLoading(true);

        try {
            const resp_tutors = await tutorService.getTutors();
            const resp_reservations = await tutorService.getTutorsReservations(day);

            // Grupos
            const listGroupsTutors = resp_tutors.map( tutor => {
                return {
                    group: 'tutor',
                    id: tutor.id,
                    nombre: tutor.name,
                    name: tutor.name,
                    tema: tutor.theme,
                    locacion: tutor.location,
                    status: tutor.status,
                };
            }).filter(item => item.status === 1);

            // Reservas
            const listItemsTutors = resp_reservations.map((item, index) => {

                let start_time = moment(item.started_at);
                let start_next_time = moment(item.ended_at);

                return {
                    id: index, // Visual ID to manipulate items in calendar
                    group: item.reserved_id,
                    available: item.status == '0',
                    start_time,
                    end_time: moment(item.ended_at),
                    title: `${moment(start_time).format('HH:mm')} - ${moment(start_next_time).add(1, 'second').format('HH:mm')}`,
                    user_id: item.user_id,
                    backend_id: item.id, // ID for API access
                    item
                };
            });

            setGroupsTutors(listGroupsTutors);
            setItemsTutors(listItemsTutors);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    // =======================================================
    /// actualiza el estado de la fecha del datepicker
    /// Consulta las salas según la fecha
    // =======================================================
    const handleDatePickerChange = picker_value => {
        const momentPickerValue = moment(picker_value, 'DD/MM/YYYY');
        setPickerValue(momentPickerValue);
        fetchRooms(momentPickerValue.format('YYYY-MM-DD'));
        fetchTutors(momentPickerValue.format('YYYY-MM-DD'));
    };

    // =======================================================
    /// Consulta los datos iniciales
    // =======================================================
    useEffect(() => {
        document.querySelector('html').style.position = 'initial';
        const fetchData = async () => {
            await fetchRooms(moment().format('YYYY-MM-DD'));
            await fetchTutors(moment().format('YYYY-MM-DD'));
        }
        fetchData();
    }, []);

    // =======================================================
    /// selecciona un item y muestra el modal
    // =======================================================
    const onScheduleRoomItemClick = (selectedTime) => {

        let item = [];

        let groupRoom = groupsRooms.find(group => group.id === selectedTime.group);

        let tool = {
            list: '',
            note: '',
        }

        if (selectedTime.item && selectedTime.item.tool && selectedTime.item.tool.list) {
            tool.list = selectedTime.item.tool.list.join(', ');
        }

        if (selectedTime.item && selectedTime.item.tool && selectedTime.item.tool.note) {
            tool.note = selectedTime.item.tool.note;
        }

        item.push({ primary_text: 'ID Usuario', secondary_text: selectedTime.item.user_id || ''});
        item.push({ primary_text: 'Usuario', secondary_text: selectedTime.item.user_full_name || ''});
        item.push({ primary_text: 'Hora de la reserva', secondary_text: selectedTime.title || ''});
        item.push({ primary_text: 'Elementos reservados', secondary_text: tool.list || ''});
        item.push({ primary_text: 'Comentarios reserva', secondary_text: tool.note || ''});
        // item.push({ primary_text: 'Título', secondary_text: selectedTime.item.title || ''});
        // item.push({ primary_text: 'Descripción', secondary_text: groupRoom.descripcion || ''});

        setItemTime(selectedTime);
        setItemSelected(item);
        setVisible(true);

    };

    // =======================================================
    /// selecciona un item y muestra el modal
    // =======================================================
    const onScheduleTutorItemClick = (selectedTime) => {

        let item = [];

        let groupTutor = groupsTutors.find(group => group.id === selectedTime.group);

        item.push({ primary_text: 'ID Usuario', secondary_text: selectedTime.item.user_id || ''});
        item.push({ primary_text: 'Usuario', secondary_text: selectedTime.item.user_full_name || '' });
        item.push({ primary_text: 'Hora de la reserva', secondary_text: selectedTime.title || '' });
        item.push({ primary_text: 'Comentarios reserva', secondary_text: selectedTime.item && selectedTime.item.tool && selectedTime.item.tool.note ? selectedTime.item.tool.note : '' });
        item.push({ primary_text: 'Descripción', secondary_text: groupTutor.tema || '' });

        setItemTime(selectedTime);
        setItemSelected(item);
        setVisible(true);

    };

    // =======================================================
    /// Cierra el modal con una tecla
    // =======================================================
    const handleKeyDown = (e) => {
        const key = e.which || e.keyCode;
        if (key === 13 || key === 32) {
            setVisible(false);
        }
    };


    // =======================================================
    /// 
    // =======================================================
    const resetModal = () => {
        setItemTime({});
        setVisibleDelete(false);
        setVisible(false);
    }

    // =======================================================
    /// 
    // =======================================================
    const elimiarReserva = async () => {

        const resp = await roomService.removeReservation(itemTime.backend_id);

        if (resp.success) {
            alert(resp.message);
            resetModal();
        } else {
            alert(resp.message);
        }

        await fetchRooms(moment(pickerValue).format('YYYY-MM-DD'));
        await fetchTutors(moment(pickerValue).format('YYYY-MM-DD'));

    }
    
    return (
        <DefaultLayout>

            <DialogContainer
                id="_simple-list-dialog"
                visible={visible}
                title="Detalles Reserva"
                dialogStyle={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 15, width: 500 }}
                onHide={resetModal}
                actions={ !visibleDelete ? [
                    <Button raised secondary disabled={itemTime.available} onClick={() => setVisibleDelete(true)}>Eliminar reserva</Button>,
                    <Button raised primary onClick={resetModal}>OK</Button>,
                ] : []}

            >
                {
                    visibleDelete ? (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <h5>¿Confirma que desea eliminar está reserva?</h5>
                            <Button flat secondary onClick={() => setVisibleDelete(false)}>No</Button>
                            <Button flat primary onClick={elimiarReserva}>Si</Button>
                            <br />
                            <br />
                        </div>
                    ) : 
                    (
                        <List onKeyDown={handleKeyDown}>
                            {
                                itemSelected.map((item, idx) => <ListItem key={`item_${idx}`} primaryText={item.primary_text} secondaryText={item.secondary_text} />)
                            }
                        </List>
                    )
                }

            </DialogContainer>

            <Card tableCard>
                <CardText>
                    <Grid className="grid-example">
                        <Cell size={6}>
                            <DatePicker
                                disableWeekEnds
                                id="date-picker-auto-ok"
                                label="Fecha"
                                className="md-cell--12"
                                locales="es"
                                displayMode="portrait"
                                value={moment(pickerValue).format('YYYY-MM-DD')}
                                onChange={value => handleDatePickerChange(value)}
                            />
                        </Cell>
                    </Grid>
                </CardText>
            </Card>

            <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />

            <Card tableCard>

                <CardText>
                    <Grid className="grid-example">

                        <Cell size={12}>
                            <TableActions title='Reservas Salas' count={0} onResetClick={() => fetchRooms(moment(pickerValue).format('YYYY-MM-DD'))} />
                        </Cell>

                        <Cell size={12}>
                            {isLoading ? (
                                <LinearProgress id='roomsProgress' />
                            ) : (
                                    <Schedule
                                        groups={groupsRooms}
                                        items={itemsRooms}
                                        dayToShow={pickerValue}
                                        titleTable="Salas"
                                        onItemClick={onScheduleRoomItemClick}
                                        groupInfo={{
                                            titleLabel: "Sala: ",
                                            titleProp: "title",
                                            subtiLabel: "Capacidad",
                                            subtiProp: "capacidad",
                                        }}
                                    />
                                )}
                        </Cell>
                    </Grid>
                </CardText>
            </Card>

            <Card tableCard>

                <CardText>
                    <Grid className="grid-example">

                        <Cell size={12}>
                            <TableActions title='Reservas Referencistas' count={0} onResetClick={() => fetchTutors(moment(pickerValue).format('YYYY-MM-DD'))} />
                        </Cell>

                        <Cell size={12}>
                            {isLoading ? (
                                <LinearProgress id='roomsProgress' />
                            ) : (
                                <Schedule
                                    groups={groupsTutors}
                                    items={itemsTutors}
                                    dayToShow={pickerValue}
                                    titleTable="Referencistas"
                                    onItemClick={onScheduleTutorItemClick}
                                    groupInfo={{
                                        titleLabel: "",
                                        titleProp: "nombre",
                                        // subtiLabel: "Capacidad",
                                        // subtiProp: "capacidad",
                                    }}
                                    />
                                )}
                        </Cell>
                    </Grid>
                </CardText>
            </Card>


        </DefaultLayout>
    );
}

export default ReservationPage;