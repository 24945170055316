import React from 'react'
import { Grid, Cell } from 'react-md'

import Header from './../organisms/header'
import NavList from './../organisms/lists/navList'

const DefaultLayout = props =>
  <React.Fragment>
    <Header />
    <Grid>
      <Cell size={3} desktopSize={2}>
        <NavList />
      </Cell>
      <Cell size={9} desktopSize={10}>
        {props.children}
      </Cell>
    </Grid>
  </React.Fragment>

export default DefaultLayout
