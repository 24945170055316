import {
  getTutorEndpoint,
  getTutorsEndpoint,
  addTutorEndpoint,
  editTutorEndpoint,
  getThemesEndpoint,
  getTutorsReservationsEndpoint,
  generalReportsTutorsEndpoint,
} from './endpoints';

import { optionsGET, optionsPOST, optionsPUT } from './options'

const tutorService = {

  getTutors: () => fetch(getTutorsEndpoint(), optionsGET())
    .then((response) => {
      if(!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json();
    }),

  getTutor: () => fetch(getTutorEndpoint(), optionsGET())
    .then((response) => {
      if(!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json();
    }),

    getThemes: () => fetch(getThemesEndpoint(), optionsGET())
    .then((response) => {
      if(!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json();
    }),

  // Consulta la lista de reservas hechas por cada sala
  getTutorsReservations: (day) => fetch(getTutorsReservationsEndpoint(day), optionsGET())
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json();
    }),

  addTutor: (data) => fetch(addTutorEndpoint(), optionsPOST(data))
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    }),

  editTutor: (data) => fetch(editTutorEndpoint(data.id), optionsPUT(data))
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    }),

  generalReportsTutors: (params) => fetch(generalReportsTutorsEndpoint(params), optionsGET())
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    })
  
/*   removeSystemUser: (id) => fetch(removeSystemUsersEndpoint(id), optionsDELETE())
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response
    }) */
}

export default tutorService
