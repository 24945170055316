export const session = JSON.parse(localStorage.getItem('session'));

const headers = (jwt) => {
  return {
    'Content-Type': 'application/json',
    Authorization: jwt ? `Bearer ${session.jwt}` : null,
  };
};

export const optionsGET = (jwt = true) => ({
  method: 'GET',
  headers: headers(jwt)
})

export const optionsPOST = (body, jwt = true) => ({
  method: 'POST',
  headers: headers(jwt),
  body: JSON.stringify(body)
})

export const optionsPUT = (body, jwt = true) => ({
  method: 'PUT',
  headers: headers(jwt),
  body: JSON.stringify(body)
})

export const optionsDELETE = (jwt = true) => ({
  method: 'DELETE',
  headers: headers(jwt),
})
