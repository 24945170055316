import React from 'react'
import {
  PieChart,
  Pie,
  Tooltip,
} from 'recharts'

const pieChart = props => (
  <PieChart
    width={props.width || 400}
    height={props.height || 200}
  >
    <Pie dataKey={props.dataKey} data={props.data} outerRadius={80} fill="#8884d8" label/>
    <Tooltip/>
  </PieChart>
)

export default pieChart
