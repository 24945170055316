import React, { useState } from 'react';
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  LinearProgress,
  TablePagination,
  TextField,
  Button,
  SelectField,
  Grid,
  Cell,
  DatePicker,
} from 'react-md';

import loanService from './../../../services/loan';

import DefaultLayout from './../../../components/layouts/default';
import TableActions from './../../../components/molecules/tableActions';

const Audit = () => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [slicedData, setSlicedData] = useState([]);
  const [state, setState] = useState({
    title: 'Auditoría',
    codigo: '',
    operation: '',
    startAt: '',
    endAt: ''
  });

  // =======================================================
  /// Consulta la información del reporte
  // =======================================================
  const getData = async (limit=5000, offset=0) => {
    setLoading(true);
    setData([]);
    setSlicedData([]);
    let params = {
      internal_user: state.codigo,
      limit,
      offset,
    }
    if (state.operation !== '') {
      params = { ...params, operation: state.operation }
    }
    if (state.startAt !== '') {
      params = { ...params, start_at: state.startAt }
    }
    if (state.endAt !== '') {
      params = { ...params, end_at: state.endAt }
    }

    try {
      const response = await loanService.userTraces(params);

      console.log(response);

      if (response[0]) {
        const data = response.map(element => ({
          Usuario: element.username,
          Dispositivo: (element.so || element.device) ? `${element.so} ${element.device}` : 'Sin información',
          Operación: element.operation,
          'Código de barras': element.barcode,
          Resultado: element.result,
          Información: element.data,
          Fecha: element.inserted_at
        }));

        setData(data);
        setSlicedData(data.slice(0, 10));
        setState(prev => ({ ...prev, title: `Préstamos por: ${response[0].username}`}) );
      }
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }

  // =======================================================
  /// Ejecuta el formulario de consulta de datos
  // =======================================================
  const handleSubmit = (event) => {
    event.preventDefault();
    getData();
  }

  // =======================================================
  /// Actualiza el listado de datos filtrados
  // =======================================================
  const handlePagination = (start, rowsPerPage) => {
    setSlicedData(data.slice(start, start + rowsPerPage));
  }

  // =======================================================
  /// Manipula los datos del estado para el formulario
  // =======================================================
  const handleInputsData = (data) => {
    setState(prev => ({ ...prev, ...data }));
  }

  return (
    <DefaultLayout>
      <Card tableCard>
        <TableActions title={state.title} count={0} onResetClick={getData} />
        <form onSubmit={handleSubmit}>
          <Grid>
            <TextField
              id="internal_user"
              label="Código del estudiante"
              lineDirection="center"
              placeholder="Ingrese el código del estudiante"
              className="md-cell md-cell--bottom"
              value={state.codigo}
              onChange={(value) => handleInputsData({codigo: value})}
              required
            />
            <SelectField
              id='operation'
              label="Operación"
              placeholder="Seleccione para filtrar"
              className="md-cell md-cell--bottom"
              menuItems={[
                { label: 'Todas', value: '' },
                { label: 'SIP2 COPIES REPORT', value: 'SIP2 COPIES REPORT' },
                { label: 'USER VALIDATE', value: 'USER VALIDATE' },
                { label: 'WEB GENERAL LOAN REPORT', value: 'WEB GENERAL LOAN REPORT' },
                { label: 'LOAN', value: 'LOAN' },
              ]}
              value={state.operation}
              onChange={(value) => handleInputsData({ operation: value })}
            />
            <Cell size={4} />
            <DatePicker
              id="date-picker-auto-ok"
              label="Desde"
              autoOk
              className="md-cell md-cell--bottom"
                onChange={(value) => handleInputsData({ startAt: value.replace(/\//g, '-').split("-").reverse().join("-") })}
              locales="es-ES"
            />
            <DatePicker
              id="date-picker-auto-ok"
              label="Hasta"
              autoOk
              className="md-cell md-cell--bottom"
                onChange={(value) => handleInputsData({ endAt: value.replace(/\//g, '-').split("-").reverse().join("-") })}
              locales="es-ES"
            />
            <Button type="submit" className="md-cell md-cell--bottom" primary flat>Buscar</Button>
          </Grid>
        </form>

        {loading && <LinearProgress id="usersProgress" />}

        <DataTable baseId="dynamic-content-desserts">
          {(!data || data.length === 0) && (
            <tbody>
              <tr>
                <td style={{ textAlign: 'center', padding: '10px' }}>
                  Ingrese el identificador del usuario y luego presione "buscar"
                </td>
              </tr>
            </tbody>
          )}

          {data && data.length > 0 && (
            <React.Fragment>
              <TableHeader>
                <TableRow>
                  {data.length > 0 && Object.keys(data[0]).map((header, i) => (
                    <TableColumn key={header}>{header}</TableColumn>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {slicedData.map((item, i) => (
                  <TableRow key={i}>
                    {Object.keys(item).map(key => (
                      <TableColumn key={key}>{item[key]}</TableColumn>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TablePagination
                rows={data.length}
                rowsPerPageLabel="Registros por página"
                onPagination={handlePagination}
              />
            </React.Fragment>
          )}
        </DataTable>
      </Card>
    </DefaultLayout>
  );
}

export default Audit;
