import React, { Component } from 'react';
import {
  DataTable,
  DialogContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardTitle,
  CardText,
  Grid,
  Cell,
  TextField,
  LinearProgress,
  Button,
  FontIcon,
  SelectionControl,
  List,
  Checkbox,
} from 'react-md';

import tutorService from './../../services/tutors';

import DefaultLayout from './../../components/layouts/default';
import TableActions from './../../components/molecules/tableActions';

class Tutors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addUser: false,
      addUserLoading: false,
      systemUsers: null,
      systemUsersLoading: false,
      isEditing: false,
      addtutorVisibility: false,
      listTitles: [],
      addTutorTheme: [],
      themesList: [],
      menuItemsThemes: [],
      dialogThemesVisible: false,
    };

    this.getSystemUsers = this.getSystemUsers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteSystemUser = this.deleteSystemUser.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.setForEdit = this.setForEdit.bind(this);
  }

  componentDidMount() {
    this.getSystemUsers();
    this.getThemes();
  }

  // =======================================================
  /// Consulta los referencistas
  // =======================================================
  getSystemUsers() {
    this.setState({ systemUsersLoading: true });
    return tutorService.getTutors()
      .then(response => {

        let tutors = response.map(item => ({
          id: item.id,
          Nombre: item.name,
          Locacion: item.location,
          Mail: item.mail,
          Facultad: item.theme,
          Perfil: item.profile,
          Foto: item.picture,
          Telefono: item.phone,
          status: item.status,
        }));
        // let listTitles = Object.keys(tutors[0]).filter(key => key !== 'status');

        let listTitles = ['id', 'Nombre', 'Locacion', 'Mail', 'Tema a Tratar', 'Perfil', 'Foto', 'Telefono'];

        this.setState({
          systemUsersLoading: false,
          systemUsers: tutors,
          listTitles
        });
      })
      .catch(error => {
        this.setState({ systemUsersLoading: false });
      });
  }

  getThemes = async () => {
    try {
      const themesList = await tutorService.getThemes();

      const menuItemsThemes = themesList.map(item => {return item.name});

      this.setState({themesList, menuItemsThemes});
    } catch (error) {
      console.log(error);
    }
  }

  handleChange(value, e) {
    this.setState({ [e.target.name]: value });
  }

  handleSelectChange = (checked, e) => {
    let {name} = e.target;
    
    const addTutorTheme = [...this.state.addTutorTheme];

    const themeId = this.state.themesList.filter(item => item.name === name)[0].id;

    if(checked === true) {
      addTutorTheme.push(themeId);
    }
    else if(checked === false) {
      let i = addTutorTheme.indexOf(themeId);
      i !== -1 && addTutorTheme.splice(i, 1);
    }

    this.setState({addTutorTheme});
  }

  showDialogThemes = () => {
    this.setState({dialogThemesVisible: true});
  }

  hideDialogThemes = () => {
    this.setState({dialogThemesVisible: false});
  }

  // =======================================================
  /// Envia datos al servidor para crear un nuevo tutor
  // =======================================================
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ addUserLoading: true });
    if (this.state.isEditing) {
      return tutorService.editTutor({
        id: this.state.addTutorId,
        name: this.state.addTutorName,
        location: this.state.addTutorLocation,
        profile: this.state.addTutorProfile,
        picture: this.state.addTutorPicture,
        theme: '[' + this.state.addTutorTheme.toString() + ']',
        phone: this.state.addTutorPhone,
        mail: this.state.addTutorMail,
        status: this.state.addtutorVisibility ? 1 : 0,
      })
        .then(() => {
          this.setState({
            addUserLoading: false,
            addUser: false,
            isEditing: false,
            addTutorId: null
          });
          this.clearForm();
          this.getSystemUsers();
        })
        .catch(error => {
          console.log(error);
          this.setState({ addUserLoading: false });
        });
    } else {
      return tutorService.addTutor({
        name: this.state.addTutorName,
        location: this.state.addTutorLocation,
        profile: this.state.addTutorProfile,
        picture: this.state.addTutorPicture,
        theme: '[' + this.state.addTutorTheme.toString() + ']',
        phone: this.state.addTutorPhone,
        mail: this.state.addTutorMail,
        status: this.state.addtutorVisibility ? 1 : 0,
      })
        .then(() => {
          this.setState({
            addUserLoading: false,
            addUser: false
          });
          this.clearForm();
          this.getSystemUsers();
        })
        .catch(error => {
          console.log(error);
          this.setState({ addUserLoading: false });
        });
    }
  }

  // =======================================================
  /// 
  // =======================================================
  setForEdit(item) {
    this.setState({
      isEditing: true,
      addUser: true,
      addTutorId: item.id,
      addTutorName: item.Nombre,
      addTutorLocation: item.Locacion,
      addTutorProfile: item.Perfil,
      addTutorMail: item.Mail,
      addTutorPhone: item.Telefono,
      addTutorTheme: JSON.parse(item.Facultad),   // Convertir de array string a un array puro
      addTutorPicture: item.Foto,
      addtutorVisibility: item.status == 1,
    })
  }

  // =======================================================
  /// Elimina un Tutor
  // =======================================================
  deleteSystemUser(item) {
    if (window.confirm(`¿Eliminar al usuario '${item.Usuario}?'`)) {
      tutorService.removeSystemUser(item.id)
        .then((response) => {
          this.getSystemUsers();
        })
        .catch(error => console.log(error));
    }
  }

  // =======================================================
  /// Cambio de estado de un referencista
  // =======================================================
  changeVisibility = (item) => {
    this.setState({ addUserLoading: true });

    return tutorService.editTutor({
      id: item.id,
      name: item.Nombre,
      location: item.Locacion,
      profile: item.Perfil,
      mail: item.Mail,
      phone: item.Telefono,
      theme: item.Facultad,
      picture: item.Foto,
      status: item.status ? 0 : 1, // envio valor inverso
    })
      .then(() => {
        this.getSystemUsers();
        this.setState({ addUserLoading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ addUserLoading: false });
      });
  }

  clearForm() {
    this.setState({
      addTutorName: null,
      addTutorLocation: null,
      addTutorProfile: null,
      addTutorMail: null,
      addTutorPhone: null,
      addTutorTheme: [],
      addTutorPicture: null,
    });
  }

  render() {
    return (
      <DefaultLayout>
        <div className='buttons__group' style={{ marginBottom: '2rem' }}>
          <Button onClick={() => this.setState({ addUser: !this.state.addUser })} floating primary>
            <FontIcon>person_add</FontIcon>
          </Button>
        </div>
        {this.state.addUser && (
          <Card>
            <CardTitle title={`${this.state.isEditing ? "Editar" : "Agregar"} Referencista`} />
            <CardText>
              <form onSubmit={this.handleSubmit}>
                <Grid>
                  <TextField
                    id='addTutorName'
                    name='addTutorName'
                    label='Nombre del Referencista'
                    lineDirection='center'
                    placeholder='Ingrese el nombre completo'
                    className='md-cell md-cell--bottom'
                    value={this.state.addTutorName}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <TextField
                    id='addTutorLocation'
                    name='addTutorLocation'
                    label='Locación del referencista'
                    lineDirection='center'
                    placeholder='Ingrese el lugar donde encontrar al referencista'
                    className='md-cell md-cell--bottom'
                    value={this.state.addTutorLocation}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <TextField
                    id='addTutorProfile'
                    name='addTutorProfile'
                    label='Perfil'
                    lineDirection='center'
                    placeholder='Ingrese la descripción del perfil del referencista'
                    className='md-cell md-cell--bottom'
                    value={this.state.addTutorProfile}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <TextField
                    id='addTutorMail'
                    name='addTutorMail'
                    type="email"
                    label='Mail'
                    lineDirection='center'
                    placeholder='Email'
                    className='md-cell md-cell--bottom'
                    value={this.state.addTutorMail}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                    required
                  />
                  <TextField
                    id='addTutorPhone'
                    name='addTutorPhone'
                    type="tel"
                    label='Telefono'
                    lineDirection='center'
                    placeholder='Número de teléfono o extensión'
                    className='md-cell md-cell--bottom'
                    value={this.state.addTutorPhone}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <Button
                    raised
                    className='md-cell md-cell--bottom'
                    style={{color: 'gray'}}
                    onClick={() => this.showDialogThemes()}
                  >
                    Seleccionar Temas
                  </Button>
                  <SelectionControl
                    id="addtutorVisibility"
                    name="addtutorVisibility"
                    type="switch"
                    label="habilitar"
                    className='md-cell md-cell--bottom'
                    checked={this.state.addtutorVisibility}
                    value={this.state.addtutorVisibility}
                    onChange={this.handleChange}
                    disabled={this.state.addUserLoading}
                  />
                  <Button
                    type='submit'
                    disabled={this.state.addUserLoading}
                    flat
                    className='md-cell md-cell--bottom'
                    primary
                    swapTheming
                  >
                    {`${this.state.isEditing ? "Editar" : "Agregar"} Referencista`}
                  </Button>
                </Grid>
              </form>
            </CardText>
          </Card>
        )}
        <DialogContainer
          id="themes-dialog"
          title="Seleccionar Temas"
          visible={this.state.dialogThemesVisible}
          onHide={() => this.hideDialogThemes()}
          width={500}
          height={700}
          actions={[{
            id: 'dialog-ok',
            primary: true,
            children: 'Aceptar',
            onClick: () => this.hideDialogThemes(),
          }]}
        >
          <List>
            <Grid>
              {this.state.menuItemsThemes.map((item, i) => 
                <Cell key={i} size={12}>
                  <Checkbox
                    id={"list-control-chat-" + i}
                    name={item}
                    label={item}
                    onChange={this.handleSelectChange}
                    checked={this.state.addTutorTheme.indexOf(this.state.themesList.filter(ele => ele.name === item)[0].id) !== -1}
                  />
                </Cell>
              )}
            </Grid>
          </List>
        </DialogContainer>
        <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
        <Card tableCard>
          <TableActions title='Referencistas' count={0} onResetClick={this.getSystemUsers} />
          {this.state.systemUsersLoading && <LinearProgress id='usersProgress' />}
            {this.state.systemUsers ? (
              <DataTable baseId='dynamic-content-desserts'>
                <React.Fragment>
                  <TableHeader>
                    <TableRow>
                      {this.state.listTitles.map((header, i) => (
                        <TableColumn key={header}>{header}</TableColumn>
                      ))}
                      <TableColumn key='actions'>Acciones</TableColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {this.state.systemUsers.map((item, i) => (
                      <TableRow key={item.id}>
                        {
                          this.state.listTitles.map(key => (
                            <TableColumn key={key}>{item[key]}</TableColumn>
                          ))
                        }
                        <TableColumn key='actions'>
                          <div className='buttons__group' style={{ marginBottom: '2rem' }}>
                            <Button onClick={() => this.changeVisibility(item)} icon>
                              <FontIcon>{item.status ? 'visibility' : 'visibility_off'}</FontIcon>
                            </Button>
                            <Button onClick={() => this.setForEdit(item)} icon>
                              <FontIcon>edit</FontIcon>
                            </Button>
                            <Button disabled onClick={() => this.deleteSystemUser(item)} icon>
                              <FontIcon>delete</FontIcon>
                            </Button>
                          </div>
                        </TableColumn>
                      </TableRow>
                    ))}
                  </TableBody>
                </React.Fragment>
              </DataTable>) : null
            }
        </Card>
      </DefaultLayout>
    );
  }
}

export default Tutors;