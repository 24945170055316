import React, { Component } from 'react'
import {
  Grid,
  Cell,
  Card,
  CardTitle,
  CardText,
  SelectionControl,
  FontIcon,
  Button
} from 'react-md'
import QRCode from 'qrcode.react'
import ReactToPrint from 'react-to-print'

import DefaultLayout from './../../components/layouts/default'

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      qrActive: false,
      qrCode: this.getRandomInt(1000000, 9999999)
    }
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  render() {
    return (
      <DefaultLayout>
        <Grid>
          <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Configuración" />
              <CardText>
                <SelectionControl
                  id="switch-persistence"
                  type="switch"
                  label="Activar persistencia en el móvil"
                  name="mobilePersistence"
                />
                <SelectionControl
                  id="switch-qr"
                  type="switch"
                  label="Activar uso de QR de seguridad"
                  name="qrCode"
                  onChange={() => this.setState({ qrActive: !this.state.qrActive })}
                />
                <SelectionControl
                  id="switch-loanNotification"
                  type="switch"
                  label="Activar notificación de correo electrónico al relizar un préstamo"
                  name="loanNotification"
                  onChange={() => {}}
                />
              </CardText>
            </Card>
          </Cell>
          {this.state.qrActive && (
            <Cell size={12}>
              <Card style={styles.card}>
                <CardTitle title="Genración de código QR" />
                <CardText>
                  <ComponentToPrint qrCode={this.state.qrCode} ref={el => (this.componentRef = el)} />
                  <ReactToPrint
                    trigger={() => <Button floating secondary><FontIcon>print</FontIcon></Button>}
                    content={() => this.componentRef}
                  />
                </CardText>
              </Card>
            </Cell>
          )}
        </Grid>
      </DefaultLayout>
    )
  }
}

class ComponentToPrint extends Component {
  render() {
    return (
      <center>
        <QRCode
          value={this.props.qrCode}
          renderAs="svg"
          size={256}
        />
        <p>{this.props.qrCode}</p>
      </center>
    )
  }
}

const styles = {
  card: {
    borderRadius: '2px',
    marginBottom: '2rem'
  },
}

export default Settings
