import React, { Component } from 'react'
import {
  Grid,
  Cell,
  Button,
  FontIcon,
  TextField,
  Snackbar,
  LinearProgress,
} from 'react-md'

class loginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      toasts: [],
      autohide: true
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.addToast = this.addToast.bind(this)
    this.dismissToast = this.dismissToast.bind(this)
  }

  handleChange(value, e) {
    this.setState({ [e.target.name]: value })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.action({
      username: this.state.username,
      password: this.state.password
    })
      .then((response) => {
        if(!response) {
          this.addToast('Usuario o contraseña incorrecto');
        }
      })
  }

  addToast(text, action, autohide = true) {
    this.setState((state) => {
      const toasts = state.toasts.slice()
      toasts.push({ text, action })
      return { toasts, autohide }
    });
  }

  dismissToast = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  }

  render () {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.props.loading && (
          <LinearProgress />
        )}
        <Grid>
          <Cell size={12}>
            <TextField
              id="loginUsername"
              label="Nombre de usuario"
              name="username"
              value={this.state.username}
              onChange={this.handleChange}
              disabled={this.props.disabled}
              required
            />
          </Cell>
          <Cell size={12}>
            <TextField
              id="loginPassword"
              label="Contraseña"
              name="password"
              type="password"
              value={this.state.password}
              passwordIcon={<FontIcon>remove_red_eye</FontIcon>}
              onChange={this.handleChange}
              disabled={this.props.disabled}
              required
            />
          </Cell>
          <Cell size={12}>
            <Button
              type="submit"
              disabled={this.props.disabled}
              flat
              primary
              swapTheming
            >
              Iniciar Sesión
            </Button>
          </Cell>
        </Grid>
        <Snackbar
          id="loginForm-snackbar"
          toasts={this.state.toasts}
          autohide={this.state.autohide}
          onDismiss={this.dismissToast}
        />
      </form>
    )
  }
}

export default loginForm
