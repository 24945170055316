import React, { useState, useEffect } from 'react';
import {
  TimePicker,
  DatePicker,
  Card,
  CardTitle,
  CardText,
  Grid,
  Checkbox,
  Button,
  FontIcon,
  SelectField,
  LinearProgress,
} from 'react-md';
import moment from 'moment';

import scheduleService from '../../services/schedule';
import tutorService from "./../../services/tutors";

import DefaultLayout from '../../components/layouts/default';

const ScheduleTutors = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [multipleDays, setMultipleDays] = useState(false);
  const [roomsList, setRoomList] = useState([]);
  const [inputs, setInputs] = useState({
    addRoomId: '',
    addUser: '',
    addRoomStartDate: '',
    addRoomEndDate: '',
    addRoomStartTime: '',
    addRoomEndTime: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const resp = await tutorService.getTutors();

      const lista = resp.map(item => ({
        value: item.id,
        label: item.name,
      }));

      setRoomList(lista);
      setIsLoading(false);
    }

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    let list_events = [];
    // console.log("inputs", inputs);
    const difference = inputs.addRoomEndDate ? moment(inputs.addRoomEndDate).diff(inputs.addRoomStartDate, 'day') : 0;

    console.log("diferencia", difference);

    for (let index = 0; index <= difference; index++) {
      console.log("index", index);
      const element = moment(inputs.addRoomStartDate).add(index, 'day');

      list_events.push({
        "type": "tutor",
        "scheduled_id": inputs.addRoomId,
        "start_at": getDateStart(element, inputs.addRoomStartTime),
        "end_at": getDateFinal(element, inputs.addRoomEndTime),
        "status": "0"
      });

    }

    console.log("list_events", list_events);

    const resp = await scheduleService.addListSchedules({ array: list_events });

    setIsLoading(false);

    if (resp.success) {
      alert('Se ha generado correctamente');
      clearForm();
    } else {
      alert(resp.message);
    }

    console.log(resp);

  }

  const getDateStart = (date, time) => {
    const fecha = moment(date).format('YYYY-MM-DD');
    const hora = moment(time).format('HH:mm:00');
    return moment(`${fecha} ${hora}`).format('YYYY-MM-DD HH:mm:ss');
  };

  const getDateFinal = (date, time) => {
    const fecha = moment(date).format('YYYY-MM-DD');
    const hora = moment(time).subtract(1, 'second').format('HH:mm:00');
    return moment(`${fecha} ${hora}`).format('YYYY-MM-DD HH:mm:ss');
  };

  const clearForm = () => setInputs({
    addRoomId: '',
    addUser: '',
    addRoomStartDate: '',
    addRoomEndDate: '',
    addRoomStartTime: '',
    addRoomEndTime: ''
  });


  const handleSelectChange = (name, value) => {
    setInputs(prev => ({ ...prev, [name]: value }));
  }

  return (
    <DefaultLayout>
      <Card>
        <CardTitle title='Agregar Referencista' />
        {isLoading && (<LinearProgress id='usersProgress' />)}
        <CardText>
          <form onSubmit={handleSubmit}>
            <Grid>
              <SelectField
                required
                id='addRoomId'
                label='Referencista'
                placeholder='Seleccione Referencista'
                className='md-cell md-cell--bottom'
                menuItems={roomsList}
                value={inputs.addRoomId}
                onChange={(value) => handleSelectChange('addRoomId', value)}
                disabled={isLoading}
              />

              <DatePicker
                required
                id="date_addRoomStartDate"
                label={`Fecha ${multipleDays ? "inicial" : ""}`}
                name="addRoomStartDate"
                className="md-cell"
                displayMode="portrait"
                value={inputs.addRoomStartDate}
                onChange={(value, val) => handleSelectChange('addRoomStartDate', val)}
                disabled={isLoading}
              />
              {
                multipleDays
                  ? (
                    <DatePicker
                      required
                      id="date_addRoomEndDate"
                      label="Fecha final"
                      name="addRoomEndDate"
                      className="md-cell"
                      displayMode="portrait"
                      value={inputs.addRoomEndDate}
                      minDate={inputs.addRoomStartDate}
                      onChange={(value, val) => handleSelectChange('addRoomEndDate', val)}
                      disabled={isLoading}
                    />
                  ) : (
                    <Checkbox
                      id="checkbox-read-material-design-spec"
                      name="simple-checkboxes"
                      label="Múltiples días para este horario"
                      onClick={() => setMultipleDays(true)}
                    />
                  )
              }
              <TimePicker
                required
                id="time_addRoomStartTime"
                label="Hora inicio"
                className="md-cell"
                defaultValue={new Date('August 19, 1975 23:00:00')}
                displayMode="portrait"
                name="addRoomStartTime"
                value={inputs.addRoomStartTime}
                onChange={(value, val) => handleSelectChange('addRoomStartTime', val)}
                disabled={isLoading}
              />

              <TimePicker
                required
                id="time_addRoomEndTime"
                label="Hora fin"
                className="md-cell"
                defaultValue={new Date('August 19, 1975 23:00:00')}
                displayMode="portrait"
                name="addRoomEndTime"
                value={inputs.addRoomEndTime}
                onChange={(value, val) => handleSelectChange('addRoomEndTime', val)}
                disabled={isLoading}
              />
              <Button
                type='submit'
                disabled={isLoading}
                flat
                className='md-cell md-cell--bottom'
                primary
                swapTheming
              >
                Agregar Horario Referencista
              </Button>
            </Grid>
          </form>
        </CardText>
      </Card>
    </DefaultLayout>
  );
}

export default ScheduleTutors;
