import React, { Component } from 'react'
import { Grid, Cell, Card, CardTitle, CardText } from 'react-md'
import { connect } from 'react-redux'

import { loginRequest } from './../store/reducers/user'

import BlankLayout from './../components/layouts/blank'
import LoginForm from './../components/organisms/forms/loginForm'

class LoginPage extends Component {
  render() {
    return (
      <BlankLayout>
        <Grid>
          <Cell size={6} offset={3}>
            <center>
              <h2>Bienvenidos a Genia Comunidad</h2>
              <h3>Administración del Sistema de Autopréstamo</h3>
            </center>
          </Cell>
        </Grid>
        <Grid>
          <Cell size={4} offset={4}>
            <Card className="md-block-centered">
              <CardTitle
                title="Ingresa"
                subtitle="Inicia sesión con tus datos de acceso"
              />
              <CardText>
                <Grid>
                  <Cell size={10} offset={1}>
                    <LoginForm
                      action={this.props.loginRequest}
                      disabled={this.props.loggingIn}
                      loading={this.props.loggedIn}
                    />
                  </Cell>
                </Grid>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </BlankLayout>
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  loggingIn: state.user.loggingIn,
})

const mapDispatchToProps = dispatch => ({
  loginRequest: (credentials) => dispatch(loginRequest(credentials))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
