import React from 'react'

import TopNavigation from './../molecules/navigation/topNavigation'

const Header = props =>
  <React.Fragment>
    <TopNavigation />
  </React.Fragment>

export default Header
