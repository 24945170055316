import {
  addScheduleEndpoint,
  generalReportsEndpoint,
  addListScheduleEndpoint,
} from './endpoints'

import { optionsGET, optionsPOST, optionsDELETE, optionsPUT } from './options'

const scheduleService = {

/*   getTutors: () => fetch(getTutorsEndpoint(), optionsGET())
  .then((response) => {
    if(!response.ok) {
      return Promise.reject(response.statusText)
    }
    return response.json()
  }),

  getTutor: () => fetch(getTutorEndpoint(), optionsGET())
    .then((response) => {
      if(!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json()
    }), */

  addSchedule: (data) => fetch(addScheduleEndpoint(), optionsPOST(data))
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    }),

  reportSchedule: (params) => fetch(generalReportsEndpoint(params), optionsGET())
    .then((response) => {
      if (!response.ok) {
        return [];
      }
      return response.json();
    }),
  addListSchedules: (data) => fetch(addListScheduleEndpoint(), optionsPOST(data))
    .then(response => {
      if (!response.ok) {
        return { success: false, message: response.statusText };
      }
      return { success: true, message: 'OK', data: response.json() }
    }),
  
/*   removeSystemUser: (id) => fetch(removeSystemUsersEndpoint(id), optionsDELETE())
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response
    }) */
}

export default scheduleService
