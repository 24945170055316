import {
  generalReportsLoanEndpoint,
  userTracesEndpoint,
  getSystemUsersEndpoint,
  addSystemUsersEndpoint,
  removeSystemUsersEndpoint,
  getSystemUserProfileEndpoint,
  updateSystemUserProfileEndpoint
} from './endpoints'

import { optionsGET, optionsPOST, optionsDELETE, optionsPUT } from './options'

const loanService = {
  userTraces: () => fetch(userTracesEndpoint(), optionsGET())
    .then((response) => {
      if(!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json()
    }),

  generalReportsLoan: (params) => fetch(generalReportsLoanEndpoint(params), optionsGET())
    .then((response) => {
      if(!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json()
    }),

  getSystemUsers: () => fetch(getSystemUsersEndpoint(), optionsGET())
  .then((response) => {
    if(!response.ok) {
      return Promise.reject(response.statusText)
    }
    return response.json()
  }),

  getSystemUserProfile: () => fetch(getSystemUserProfileEndpoint(), optionsGET())
    .then((response) => {
      if(!response.ok) {
        return Promise.reject(response.statusText)
      }
      return response.json()
    }),

  updateSystemUserProfile: (data) => fetch(updateSystemUserProfileEndpoint(), optionsPUT(data))
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    }),

  addSystemUser: (data) => fetch(addSystemUsersEndpoint(), optionsPOST(data))
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json()
    }),
  
  removeSystemUser: (id) => fetch(removeSystemUsersEndpoint(id), optionsDELETE())
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response
    })
}

export default loanService
