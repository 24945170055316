
export const MONTH_ITEMS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
export const YEAR_ITEMS = () => {
    let year = new Date().getFullYear();
    let yearItems = [];
    
    for (let i = 2018; i <= parseInt(year); i++) {
        yearItems.push(i.toString());
    }
    
    return yearItems.sort((a, b) => {return b - a});
};