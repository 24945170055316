import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Workbook from 'react-excel-workbook';

import {
    SelectField,
    DataTable,
    TableHeader,
    TableBody,
    TableRow,
    TableColumn,
    Card,
    CardTitle,
    CardText,
    LinearProgress,
    TablePagination,
    Grid,
    Cell,
    Button,
    DatePicker,
    SelectionControlGroup,
} from 'react-md';

// API
import tutorService from '../../../services/tutors';
import roomService from '../../../services/rooms';
import scheduleService from '../../../services/schedule';

import BarChart from '../../../components/molecules/charts/barChart'
import LineChart from '../../../components/molecules/charts/lineChart';
import TableActions from '../../../components/molecules/tableActions';

import { MONTH_ITEMS, YEAR_ITEMS } from '../../../constants/commons';

import FlatOrIconButton from '../../../components/atoms/flatOrIconButton';

const ScheduleReport = (props) => {

    const [loading, setLoading] = useState(false);
    const user = useSelector( state => state.user.user || {id:''} );
    const [state, setState] = useState({
        historicData: [],
        slicedHistoricData: [],
        chartInfoText: '',
        operation: '',
        historicDataStartAt: '',
        historicDataEndAt: '',
        salas: {
            data: [],
            total: 0,
        },
        tutores: {
            data: [],
            total: 0,
        },
        scheduleTableData: [],
        slicedScheduleTableData: [],
        scheduleStartMonth: '',
        scheduleStartYear: '',
        scheduleEndMonth: '',
        scheduleEndYear: '',
        showScheduleCustomRangePickers: false,
        filter_option: 'month',
        showMonthlyData: false,
    });
    const [filterReportRange, setFilterReportRange] = useState('');
    const [reportsTypesItems, setReportsTypesItems] = useState([]);

    const styles = {
        card: {
            borderRadius: '2px',
            marginBottom: '2rem',
        },
    };

    // =======================================================
    /// 
    // =======================================================
    const formatDate = (date) => {
        const startWithYear = /^[0-9]{4}/.test(date);
        const newDate = startWithYear ? date.slice(0, 10).split('-') : date.slice(0, 10).split('/');
        const hour = date.slice(11);
        const day = startWithYear ? newDate[2] : newDate[0];
        const month = newDate[1];
        const year = startWithYear ? newDate[0] : newDate[2];
        return `${day}/${month}/${year} ${hour}`;
    }

    // =======================================================
    /// 
    // =======================================================
    const handleHistoricDataPagination = (start, rowsPerPage) => {
        setState(prev => ({
            ...prev,
            slicedHistoricData: prev.historicData.slice(start, start + rowsPerPage)
        }));
    };

    // =======================================================
    /// 
    // =======================================================
    const handleSchedulePagination = (start, rowsPerPage) => {
        setState(prev => ({
            ...prev,
            slicedScheduleTableData: prev.scheduleTableData.slice(start, start + rowsPerPage)
        }));
    };

    // =======================================================
    /// Filtra los datos consultados agrupandolos por mes
    // =======================================================
    const filterMonth = (salasData, tutoresData) => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce((prev, mes) => {

            let _sala = salasData.filter(item => parseInt(moment(item.Fecha).format('MM')) === mes);
            let _tutores = tutoresData.filter(item => parseInt(moment(item.Fecha).format('MM')) === mes);

            if (_sala.length > 0 || _tutores.length > 0) {

                let suma_sala = _sala && _sala.length > 0 ? _sala.reduce( (prev, data) => prev + data.Salas , 0) : 0;
                let suma_tutores = _tutores && _tutores.length > 0 ? _tutores.reduce((prev, data) => prev + data.Tutores, 0) : 0;

                return [...prev, {
                    Mes: MONTH_ITEMS[mes - 1],
                    Salas: suma_sala,
                    Tutores: suma_tutores,
                }];

            }

            return prev;
        }, []);
    }

    // =======================================================
    /// 
    // =======================================================
    const getTutorsAndRooms = async (type) => {

        let params = {};
        let chartInfoText = '';

        switch (type) {
            case 'range':
                if (
                    state.scheduleStartYear == '' || state.scheduleStartMonthNumber == '' ||
                    state.scheduleEndYear == '' || state.scheduleEndMonthNumber == ''
                ) {
                    alert("Error: Ingrese la información completa de Mes y Año de Inicio y Fin")
                    return;
                }
                const startDate = moment(`${state.scheduleStartYear}-${state.scheduleStartMonthNumber}-01`, 'YYYY-MM-DD');
                const endDate = moment(`${state.scheduleEndYear}-${state.scheduleEndMonthNumber}-01`, 'YYYY-MM-DD');

                if (endDate < startDate) {
                    alert("Error: El mes de inicio debe ser antes del mes final.");
                    return;
                }

                params = {
                    start_at: moment(startDate).format('YYYY-MM-DD'),
                    end_at: moment(endDate).format('YYYY-MM-DD'),
                    type: 'range',
                }
                chartInfoText = `Mostrando desde ${state.scheduleStartMonth} ${state.scheduleStartYear} hasta ${state.scheduleEndMonth} ${state.scheduleEndYear}.`;
                break;

            case 'day':
                params = {
                    type: 'day',
                }
                chartInfoText = 'Mostrando Hoy';
                break;

            case 'week':
                params = {
                    type: 'week',
                }
                chartInfoText = 'Mostrando Últimos 7 Días';
                break;

            case 'month':
                params = {
                    type: 'month',
                }
                chartInfoText = 'Mostrando Último Mes';
                break;

        }

        setLoading(true);
        setState(prev => ({
            ...prev,
            salas: {
                data: [],
                total: 0
            },
            tutores: {
                data: [],
                total: 0
            },
            chartInfoText
        }));

        const salasData = await getSalas(params);
        const tutoresData = await getTutores(params);

        let tableData = [];

        if (type === 'month' ) {
            tableData = filterMonth(salasData, tutoresData);
        }else
            tableData = [...salasData, ...tutoresData];

        setState(prev => ({
            ...prev,
            salas: {
                data: salasData,
                total: salasData.reduce((total, element) => {
                    return total + element.Salas;
                }, 0),
            },
            tutores: {
                data: tutoresData,
                total: tutoresData.reduce((total, element) => {
                    return total + element.Tutores;
                }, 0),
            },
            showMonthlyData: type == 'month',
            typeChar: type == 'range' ? 'bars' : 'line',
            scheduleTableData: tableData,
            slicedScheduleTableData: tableData.slice(0, 10)
        }));

        setLoading(false);
    }

    // =======================================================
    /// Consulta información para grafica de salas
    // =======================================================
    const getSalas = async (params) => {
        const respuesta = await roomService.generalReportsRoom({ ...params, reserved: 'room'});

        if (params.type == 'range') {

            return respuesta.reduce((prev, current) => {

                const { count, trace_timestamp } = current;
                const mes = moment(trace_timestamp).format('MM-YYYY');

                const mesExiste = prev.find(data => data.Fecha == mes);
                const restantes = prev.filter(data => data.Fecha != mes);

                if (!mesExiste) {
                    return [...prev, {
                        Salas: count,
                        Fecha: moment(trace_timestamp).format('MM-YYYY'),
                        trace_timestamp: moment(trace_timestamp).format('YYYY-MM-01'),
                    }];
                }

                const sumatoria = mesExiste.Salas + count;

                return [...restantes, { Fecha: mes, Salas: sumatoria }];

            }, [])
                .sort((prev, next) => {
                    if (moment(prev.trace_timestamp) < moment(next.trace_timestamp)) return -1;
                    if (moment(prev.trace_timestamp) > moment(next.trace_timestamp)) return 1;
                    return 0;
                });

        } else {

            return respuesta.map(element => ({
                Salas: element.count,
                Fecha: element.trace_timestamp,
            }))
                .sort((prev, next) => {
                    if (moment(prev.Fecha) < moment(next.Fecha)) return -1;
                    if (moment(prev.Fecha) > moment(next.Fecha)) return 1;
                    return 0;
                });

        }

    }

    // =======================================================
    /// Consulta información para graficas de tutores
    // =======================================================
    const getTutores = async (params) => {
        const respuesta = await tutorService.generalReportsTutors({ ...params, reserved: 'tutor' });
        
        if (params.type == 'range') {
            
            return respuesta.reduce((prev, current) => {

                const { count, trace_timestamp } = current;
                const mes = moment(trace_timestamp).format('MM-YYYY');

                const mesExiste = prev.find(data => data.Fecha == mes);
                const restantes = prev.filter(data => data.Fecha != mes);

                if (!mesExiste) {
                    return [...prev, {
                        Tutores: count,
                        Fecha: moment(trace_timestamp).format('MM-YYYY'),
                        trace_timestamp: moment(trace_timestamp).format('YYYY-MM-01'),
                    }];
                }

                const sumatoria = mesExiste.Tutores + count;

                return [...restantes, { Fecha: mes, Tutores: sumatoria }];

            }, [])
                .sort((prev, next) => {
                    if (moment(prev.trace_timestamp) < moment(next.trace_timestamp)) return -1;
                    if (moment(prev.trace_timestamp) > moment(next.trace_timestamp)) return 1;
                    return 0;
                });

        } else {
            
            return respuesta.map(element => ({
                Tutores: element.count,
                Fecha: element.trace_timestamp,
            }))
                .sort((prev, next) => {
                    if (moment(prev.Fecha) < moment(next.Fecha)) return -1;
                    if (moment(prev.Fecha) > moment(next.Fecha)) return 1;
                    return 0;
                });


        }
    }

    // =======================================================
    /// Retorna el reporte completo de datos historicos
    // =======================================================
    const getHistory = async (params) => {
        const tipos = {
            'Salas': 'room',
            'Tutores': 'tutor',
        }
        const respuesta = await scheduleService.reportSchedule({ started_at: params.start_at, ended_at: params.end_at, type: tipos[params.type], status: 1});
        
        const list_history = respuesta.map( item => {
            if(item.type == "room")
                return {
                    usuario: item.user_mail || '',
                    programa: item.department,
                    sala: item.reserved.name,
                    herramientas: item.tool && item.tool.list ? item.tool.list.join(', ') : '',
                    inicio: item.started_at ? item.started_at.replace('T', ' ') : '',
                    fin: item.ended_at ? item.ended_at.replace('T', ' ') : '',
                };
            else
                return {
                    usuario: item.user_mail || '',
                    programa: item.department,
                    referencista: item.reserved.name,
                    tema: item.tool?.note || '',
                    inicio: item.started_at ? item.started_at.replace('T', ' ') : '',
                    fin: item.ended_at ? item.ended_at.replace('T', ' ') : '',
                };
        } );

        return list_history;
    }

    // =======================================================
    /// 
    // =======================================================
    const getHistoricData = async () => {

        if (state.historicDataStartAt === '') {
            alert("Seleccione una fecha de inicio.");
            return;
        }
        if (state.historicDataEndAt === '') {
            alert("Seleccione una fecha de fin.");
            return;
        }
        if ( filterReportRange === '' ) {
            alert("Debe seleccionar un tipo de reporte");
            return;
        }

        const startDate = moment(state.historicDataStartAt).format('YYYY-MM-DD');
        const endDate = moment(state.historicDataEndAt).format('YYYY-MM-DD');
        const daysDifference = moment(endDate).diff(startDate, 'days');

        if (daysDifference >= 90) {
            alert("El rango debe ser de máximo 90 días.");
            return;
        }

        setLoading(true);
        setState(prev => ({
            ...prev,
            historicData: [],
            slicedHistoricData: [],
        }));

        let params = {
            start_at: startDate,
            end_at: endDate,
            type: filterReportRange
        };

        const reservasData = await getHistory(params);

        setState(prev => ({
            ...prev,
            historicData: reservasData,
            showMonthlyData: 'history',
            slicedHistoricData: reservasData.slice(0, 10),
        }));
        setLoading(false);
    }

    // =======================================================
    /// 
    // =======================================================
    useEffect(() => {
        getTutorsAndRooms('month');

        if (props.roomsBoolean && props.tutorsBoolean) {
            setReportsTypesItems(['Salas', 'Tutores']);
        }
        else if (props.roomsBoolean && !props.tutorsBoolean) {
            setReportsTypesItems(['Salas']);
        }
        else if (!props.roomsBoolean && props.tutorsBoolean) {
            setReportsTypesItems(['Tutores']);
        }
        else {
            setReportsTypesItems([]);
        }
    }, []);

    return (
        <Grid style={{ width: "100%" }}>
            <Cell size={12}>
                <Card style={styles.card}>
                    <Grid>
                        {props.roomsBoolean && <CardTitle title={state.salas.total} subtitle='Salas' />}
                        {props.tutorsBoolean && <CardTitle title={state.tutores.total} subtitle='Tutores' />}
                        <CardTitle title={''} subtitle='' />
                    </Grid>
                    <CardText>

                        {(loading) && (<LinearProgress id='loanProgress' />)}

                        {state.salas.data && (
                            <React.Fragment>
                                <hr />

                                <SelectionControlGroup
                                    id="selection-control-group-radios"
                                    name="radio-example"
                                    type="radio"
                                    defaultValue="month"
                                    value={state.filter_option}
                                    onChange={event => {
                                        if (event === 'range') {
                                            setState(prev => ({ ...prev, showScheduleCustomRangePickers: true, filter_option: event }))
                                        } else {
                                            setState(prev => ({
                                                ...prev,
                                                showScheduleCustomRangePickers: false,
                                                showMonthlyData: false,
                                                filter_option: event
                                            }));
                                            getTutorsAndRooms(event);
                                        }
                                    }}
                                    controls={[{
                                        label: 'Hoy',
                                        value: 'day',
                                    }, {
                                        label: 'Últimos 7 días',
                                        value: 'week',
                                    }, {
                                        label: 'Último Mes',
                                        value: 'month',
                                    }, {
                                        label: 'Consolidado Mensual',
                                        value: 'range',
                                    }]}
                                />

                                {
                                    state.showScheduleCustomRangePickers && (
                                        <div>
                                            <div>
                                                <SelectField
                                                    simplifiedMenu
                                                    id='start-month-select-field'
                                                    label='Mes (Inicio)'
                                                    placeholder='Mes (Inicio)'
                                                    className='md-cell'
                                                    menuItems={MONTH_ITEMS}
                                                    value={state.scheduleStartMonth}
                                                    onChange={(value, index) => {
                                                        setState(prev => ({
                                                            ...prev,
                                                            scheduleStartMonth: value,
                                                            scheduleStartMonthNumber: '' + (index + 1)
                                                        }))
                                                    }
                                                    }
                                                />
                                                <SelectField
                                                    id='start-year-select-field'
                                                    label='Año (Inicio)'
                                                    placeholder='Año (Inicio)'
                                                    className='md-cell'
                                                    menuItems={YEAR_ITEMS()}
                                                    simplifiedMenu={false}
                                                    value={state.scheduleStartYear}
                                                    onChange={(value) => {
                                                        setState(prev => ({ ...prev, scheduleStartYear: value }))
                                                    }
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <SelectField
                                                    simplifiedMenu
                                                    id='end-month-select-field'
                                                    label='Mes (Fin)'
                                                    placeholder='Mes (Fin)'
                                                    className='md-cell'
                                                    menuItems={MONTH_ITEMS}
                                                    value={state.scheduleEndMonth}
                                                    onChange={(value, index) => {
                                                        setState(prev => ({
                                                            ...prev,
                                                            scheduleEndMonth: value,
                                                            scheduleEndMonthNumber: '' + (index + 1)
                                                        }))
                                                    }}
                                                />
                                                <SelectField
                                                    simplifiedMenu
                                                    id='end-year-select-field'
                                                    label='Año (Fin)'
                                                    placeholder='Año (Fin)'
                                                    className='md-cell'
                                                    menuItems={YEAR_ITEMS()}
                                                    value={state.scheduleEndYear}
                                                    onChange={(value, index) => {
                                                        setState(prev => ({ ...prev, scheduleEndYear: value }))
                                                    }}
                                                />
                                            </div>

                                            <Button
                                                primary
                                                flat
                                                swapTheming
                                                type='submit'
                                                className='md-cell md-cell--bottom'
                                                onClick={() => getTutorsAndRooms('range')}
                                            >
                                                Buscar
                                            </Button>
                                        </div>
                                    )
                                }

                                <hr />

                                <CardText>{state.chartInfoText}</CardText>

                                {
                                    state.typeChar === 'line' ? (

                                        <Grid>
                                            {props.roomsBoolean && 
                                            <LineChart
                                                width={480}
                                                height={300}
                                                data={state.salas.data}
                                                dataKeyX='Fecha'
                                                dataKeyY='Salas'
                                                lineColor='#8884d8'
                                            />}
                                            {props.tutorsBoolean && 
                                            <LineChart
                                                width={480}
                                                height={300}
                                                data={state.tutores.data}
                                                dataKeyX='Fecha'
                                                dataKeyY='Tutores'
                                                lineColor='#82ca9d'
                                            />}
                                        </Grid>

                                    ) : (

                                        <Grid>
                                            {props.roomsBoolean && 
                                            <BarChart
                                                width = { 480 }
                                                height = { 300 }
                                                data = {state.salas.data}
                                                dataKeyX='Fecha'
                                                dataKeyY='Salas'
                                                barColor='#8884d8'
                                            />}
                                            {props.tutorsBoolean && 
                                            <BarChart
                                                width={480}
                                                height={300}
                                                data={state.tutores.data}
                                                dataKeyX='Fecha'
                                                dataKeyY='Tutores'
                                                barColor='#82ca9d'
                                            />}
                                        </Grid>

                                    )
                                }

                            </React.Fragment>
                        )}

                    </CardText>
                </Card>

                
                <Card tableCard>
                    <TableActions title='Histórico Reservas' count={0} onResetClick={getHistoricData} />

                    {loading && <LinearProgress id='usersProgress' />}

                    <Grid>
                        <DatePicker
                            id='date-picker-auto-ok'
                            label='Desde'
                            autoOk
                            className='md-cell md-cell--bottom'
                            name="historicDataStartAt"
                            value={state.historicDataStartAt}
                            onChange={(value, date) =>
                                setState(prev => ({ ...prev, historicDataStartAt: moment(date).format('YYYY-MM-DD') }))}
                            locales='es-ES'
                        />
                        <DatePicker
                            id='date-picker-auto-ok'
                            label='Hasta'
                            autoOk
                            className='md-cell md-cell--bottom'
                            name="historicDataEndAt"
                            value={state.historicDataEndAt}
                            onChange={(value, date) => 
                                setState(prev => ({ ...prev, historicDataEndAt: moment(date).format('YYYY-MM-DD') }))
                            }
                            locales='es-ES'
                        />
                        <SelectField
                            id="filterReportRange"
                            label="Tipo de reporte"
                            menuItems={reportsTypesItems}
                            onChange={value => setFilterReportRange(value)}
                            value={filterReportRange}
                            itemLabel="name"
                            name="filterReportRange"
                            className="md-cell md-cell--bottom"
                        />
                        <Button
                            type='submit'
                            className='md-cell md-cell--bottom'
                            onClick={getHistoricData}
                            primary
                            flat
                        >
                            Buscar
                        </Button>
                        <Workbook
                            filename={filterReportRange == 'Salas' ? 'ReporteDeReservasSalas.xlsx' : 'ReporteDeReservasTutores.xlsx'}
                            element={
                                <Button
                                    raised
                                    primary
                                    iconEl={<FlatOrIconButton
                                        iconChildren='folder'
                                        style={{ color: '#fff' }}>{loading ? 'Cargando...' : '»Exportar'}</FlatOrIconButton>}
                                />
                            }>
                            {
                                filterReportRange == 'Salas'
                                    ? (
                                        <Workbook.Sheet
                                            data={state.historicData}
                                            name='Reportes'
                                        >
                                            <Workbook.Column
                                                label='Usuario'
                                                value='usuario' />
                                            <Workbook.Column
                                                label='Programa Académico'
                                                value='programa' />
                                            <Workbook.Column
                                                label='Sala'
                                                value='sala' />
                                            <Workbook.Column
                                                label='Herramientas'
                                                value='herramientas' />
                                            <Workbook.Column
                                                label='Fecha Inicio'
                                                value='inicio' />
                                            <Workbook.Column
                                                label='Fecha Fin'
                                                value='fin' />
                                        </Workbook.Sheet>
                                    )
                                    : (
                                        <Workbook.Sheet
                                            data={state.historicData}
                                            name='Reportes'
                                        >
                                            <Workbook.Column
                                                label='Usuario'
                                                value='usuario' />
                                            <Workbook.Column
                                                label='Programa Académico'
                                                value='programa' />
                                            <Workbook.Column
                                                label='Referencista'
                                                value='referencista' />
                                            <Workbook.Column
                                                label='Tema'
                                                value='tema' />
                                            <Workbook.Column
                                                label='Fecha Inicio'
                                                value='inicio' />
                                            <Workbook.Column
                                                label='Fecha Fin'
                                                value='fin' />
                                        </Workbook.Sheet>
                                    )
                            }
                        </Workbook>
                    </Grid>
                    <DataTable baseId='dynamic-content-desserts'>
                        {state.historicData.length === 0 && (
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center', padding: '10px' }}>Sin Registro</td>
                                </tr>
                            </tbody>
                        )}
                        {state.slicedHistoricData.length > 0 && (
                            <React.Fragment>
                                <TableHeader>
                                    <TableRow>
                                        {Object.keys(state.slicedHistoricData[0]).map((header, i) => (
                                            <TableColumn key={`${header}_${i}`}>{header}</TableColumn>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {state.slicedHistoricData.map((item, i) => (
                                        <TableRow key={`${item.id}_${i}`}>
                                            {Object.keys(item).map(key => (
                                                <TableColumn key={key}>{item[key]}</TableColumn>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TablePagination
                                    rows={state.historicData.length}
                                    rowsPerPageLabel='Registros por página'
                                    onPagination={handleHistoricDataPagination}
                                />
                            </React.Fragment>
                        )}
                    </DataTable>
                </Card>


            </Cell>
        </Grid>
    );
}

export default ScheduleReport;