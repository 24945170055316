import AuthenticationService from './../../services/authentication'

const LOGIN_REQUEST = 'LOGIN_REQUEST'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'

const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

const APPTYPE_SUCCESS = 'APPTYPE_SUCCESS'
const APPTYPE_FAILURE = 'APPTYPE_FAILURE'

export const loginRequest = payload => (dispatch) => AuthenticationService.logIn(payload)
  .then((response) => {
    dispatch({ type: LOGIN_SUCCESS, payload: response.user });
    return true;
  })
  .catch((error) => {
    dispatch({ type: LOGIN_FAILURE, payload: error });
    return false;
  })

export const logoutRequest = () => (dispatch) => AuthenticationService.logOut()
  .then((response) => {
    dispatch({ type: LOGOUT_SUCCESS, payload: response.user });
    return true;
  })
  .catch((error) => {
    dispatch({ type: LOGOUT_FAILURE, payload: error });
    //return false
    // dispatch({ type: LOGOUT_FAILURE, payload: response.user })
    return true;
  })

export const appTypeRequest = () => (dispatch) => AuthenticationService.appType()
  .then((response) => {
    dispatch({ type: APPTYPE_SUCCESS, payload: response.modules });
    return true;
  })
  .catch((error) => {
    dispatch({ type: APPTYPE_FAILURE, payload: error });
    return false;
  })

const user = JSON.parse(localStorage.getItem('user'))
const session = JSON.parse(localStorage.getItem('session'))
const expire = (session) ? session.exp : null
const validSession = (user && expire && expire < Date.now())

const initialState = {
  user: validSession ? user : null,
  loggedIn: !!validSession,
  loggingIn: false,
  msg: {
    type: null,
    response: null,
  }
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        user: action.payload
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        msg: {
          type: LOGIN_FAILURE,
          response: action.payload,
        }
      }

    case LOGOUT_REQUEST:
      return state

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        user: null
      }

    case LOGOUT_FAILURE:
      return {
        ...state,
        msg: {
          type: LOGOUT_FAILURE,
          response: action.payload,
        }
      }

    case APPTYPE_SUCCESS:
      return {
        ...state,
        appType: action.payload
      }

    case APPTYPE_FAILURE:
      return {
        ...state,
        msg: {
          type: APPTYPE_FAILURE,
          response: action.payload,
        }
      }

    default:
      return state
  }
}

export default userReducer
