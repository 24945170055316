import React, {useState, useEffect} from 'react';
import _, { isObject } from 'lodash';
import moment from 'moment';
import Workbook from 'react-excel-workbook';

import {
  SelectField,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardTitle,
  CardText,
  LinearProgress,
  TablePagination,
  Grid,
  Cell,
  Button,
  DatePicker,
  SelectionControlGroup,
  Checkbox,
} from 'react-md';

import loanService from '../../../services/loan';
import BarChart from '../../../components/molecules/charts/barChart'
import LineChart from '../../../components/molecules/charts/lineChart';
import TableActions from '../../../components/molecules/tableActions';

import { MONTH_ITEMS, YEAR_ITEMS } from '../../../constants/commons';

import FlatOrIconButton from '../../../components/atoms/flatOrIconButton';
import { FormatDateExtern } from '../../../services/endpoints';

const LoanReport = () => {

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    historicData: [],
    slicedHistoricData: [],
    chartInfoText: '',
    operation: '',
    historicDataStartAt: '',
    historicDataEndAt: '',
    loan: {
      data: [],
      total: 0,
    },
    renewals: {
      data: [],
      total: 0,
    },
    loanAndRenewalsTableData: [],
    slicedLoanAndRenewalsTableData: [],
    loanStartMonth: '',
    loanStartYear: '',
    loanEndMonth: '',
    loanEndYear: '',
    showLoanCustomRangePickers: false,
    filter_option: 'month',
  });

  const styles = {
    card: {
      borderRadius: '2px',
      marginBottom: '2rem',
    },
  };

  // =======================================================
  /// 
  // =======================================================
  const formatDate = (date) => {
    const startWithYear = /^[0-9]{4}/.test(date);
    const newDate = startWithYear ? date.slice(0, 10).split('-') : date.slice(0, 10).split('/');
    const hour = date.slice(11,);
    const day = startWithYear ? newDate[2] : newDate[0];
    const month = newDate[1];
    const year = startWithYear ? newDate[0] : newDate[2];
    return `${day}/${month}/${year} ${hour}`;
  }

  const formatDateExtern = (date) => {
    //const mask_in = "dd/MM/yyyy hh:mm";//"yyyyMMdd     hhmmss";
    //const mask_out = "dd/MM/yyyy hh:mm:ss"; 

    const year_start = FormatDateExtern.indexOf("yyyy");
    const year_end = year_start + 4;

    const month_start = FormatDateExtern.indexOf("MM");
    const month_end = month_start + 2;

    const day_start = FormatDateExtern.indexOf("dd");
    const day_end = day_start + 2;

    const hour_start = FormatDateExtern.indexOf("hh");
    const hour_end = hour_start + 2;

    const minute_start = FormatDateExtern.indexOf("mm");
    const minute_end = minute_start + 2;

    const sec_start = FormatDateExtern.indexOf("ss");
    const sec_end = sec_start + 2;

    const year_st = date.slice(year_start, year_end);
    const month_st = date.slice(month_start, month_end).padStart(2, '0');
    const day_st = date.slice(day_start, day_end).padStart(2, '0');
    const hour_st = date.slice(hour_start, hour_end).padStart(2, '0');
    const minute_st = date.slice(minute_start, minute_end).padStart(2, '0');
    const sec_st = date.slice(sec_start, sec_end).padStart(2, '0');

    return `${day_st}/${month_st}/${year_st} ${hour_st}:${minute_st}:${sec_st}`;
  }

  // =======================================================
  /// 
  // =======================================================
  const handleHistoricDataPagination = (start, rowsPerPage) => {
    setState(prev => ({
      ...prev,
      slicedHistoricData: prev.historicData.slice(start, start + rowsPerPage)
    }));
  };

  // =======================================================
  /// 
  // =======================================================
  const handleLoanAndRenewalsPagination = (start, rowsPerPage) => {
    setState(prev => ({
      ...prev,
      slicedLoanAndRenewalsTableData: prev.loanAndRenewalsTableData.slice(start, start + rowsPerPage)
    }));
  };

  // =======================================================
  /// 
  // =======================================================
  const setLoanAndRenewalsTableData = (loan, renewals) => {
    let tableData = [];

    loan.forEach((item, index) => {
      const monthNumber = parseInt(item.Fecha.substring(5, 7));
      const monthString = MONTH_ITEMS[monthNumber - 1];
      const yearString = item.Fecha.substring(0, 4);

      tableData.push({
        'Mes': monthString + ' ' + yearString,
        'Préstamos': item['Préstamos'],
        'Renovaciones': renewals.length !== 0 ? renewals[index].Renovaciones : 0,
      });
    });

    setState(prev => ({
      ...prev,
      loanAndRenewalsTableData: tableData.reverse(),
      slicedLoanAndRenewalsTableData: tableData.slice(0, 10)
    }));

  }

  // =======================================================
  /// 
  // =======================================================
  const getLoansAndRenewals = async (type) => {

    let params = {
      type
    };
    let monthly = false;
    let chartInfoText = '';

    switch (type) {
      case 'range':
        if (
          state.loanStartYear == '' || state.loanStartMonthNumber == '' ||
          state.loanEndYear == '' || state.loanEndMonthNumber == ''
        ) {
          alert("Error: Ingrese la información completa de Mes y Año de Inicio y Fin")
          return
        }
        const startDate = moment(`${state.loanStartYear}-${state.loanStartMonthNumber}-01`, 'YYYY-MM-DD');
        const endDate = moment(`${state.loanEndYear}-${state.loanEndMonthNumber}-01`, 'YYYY-MM-DD');

        if (endDate < startDate) {
          alert("Error: El mes de inicio debe ser antes del mes final.");
          return;
        }

        params = {
          start_at: startDate.format('YYYY-MM-DD'),
          end_at: endDate.format('YYYY-MM-DD'),
          type: 'range',
        }
        monthly = true;
        chartInfoText = `Mostrando desde ${state.loanStartMonth} ${state.loanStartYear} hasta ${state.loanEndMonth} ${state.loanEndYear}.`;
        break;

      case 'day':
        chartInfoText = 'Mostrando Hoy'
        break;

      case 'week':
        chartInfoText = 'Mostrando Últimos 7 Días'
        break;

      case 'month':
        chartInfoText = 'Mostrando Último Mes'
        break;

    }

    setLoading(true);
    setState(prev => ({
      ...prev,
      loan: {
        data: null,
        total: 0
      },
      renewals: {
        data: null,
        total: 0
      },
      chartInfoText
    }));

    const loansData = await getLoan({...params, operation: 'LOAN'}, monthly);
    const renewalsData = await getRenewals({...params, operation: 'RENEW'}, monthly);

    setState(prev => ({
      ...prev,
      loan: {
        data: loansData,
        total: loansData.reduce((total, element) => {
          return total + element.Préstamos;
        }, 0),
      },
      renewals: {
        data: renewalsData,
        total: renewalsData.reduce((total, element) => {
          return total + element.Renovaciones;
        }, 0),
      },
      showMonthlyData: type === 'range'
    }));

    // #validar
    setLoanAndRenewalsTableData(loansData, renewalsData);
    setLoading(false);
  }

  // =======================================================
  /// 
  // =======================================================
  const getLoan = async (params, monthly) => {

    const respuesta = await loanService.generalReportsLoan(params, monthly);
    const map_ok = respuesta.map(element => ({
        Préstamos: element.count,
        result: element.result,
        Fecha: element.trace_date,
      }))
      .sort((prev, next) => {
        if (moment(prev.Fecha) < moment(next.Fecha)) return -1;
        if (moment(prev.Fecha) > moment(next.Fecha)) return 1;
        return 0;
      })
      .map(el => ({
        ...el,
        Fecha: monthly ? ( el.Fecha ) : (`${moment(el.Fecha).format('DD-MM-YYYY')} / ${el.Fecha.slice(11,)}`),
      }))
      .filter((item) => {
        return item.result === 'OK';
      });

    return map_ok;
  }

  // =======================================================
  /// 
  // =======================================================
  const getRenewals = async (params, monthly) => {
    const respuesta = await loanService.generalReportsLoan(params, monthly);
    const map_ok = respuesta.map(element => ({
        Renovaciones: element.count,
        result: element.result,
        Fecha: element.trace_date,
      }))
      .sort((prev, next) => {
        if (moment(prev.Fecha) < moment(next.Fecha)) return -1;
        if (moment(prev.Fecha) > moment(next.Fecha)) return 1;
        return 0;
      })
      .map(el => ({
        ...el,
        Fecha: monthly ? (el.Fecha) : (`${moment(el.Fecha).format('DD-MM-YYYY')} / ${el.Fecha.slice(11)}`),
      }))
      .filter((item) => {
        return item.result === 'OK';
      });
    return map_ok;
  }

  // =======================================================
  /// 
  // =======================================================
  const getHistoricData = async () => {

    if (state.historicDataStartAt === '') {
      alert("Seleccione una fecha de inicio.");
      return;
    }
    if (state.historicDataEndAt === '') {
      alert("Seleccione una fecha de fin.");
      return;
    }

    const startDate = moment(state.historicDataStartAt, 'YYYY-MM-DD');
    const endDate = moment(state.historicDataEndAt, 'YYYY-MM-DD');
    const daysDifference = endDate.diff(startDate, 'days');

    if (daysDifference >= 180) {
      alert("El rango debe ser de máximo 180 días.");
      return;
    }

    setLoading(true);
    setState(prev => ({
      ...prev,
      historicData: [],
      slicedHistoricData: [],
    }));
    var cbAsc = document.getElementById('chb_asc');
    var asc = "desc";
    if(cbAsc.checked){asc = "asc"}
console.log(asc);
    let params = {
      limit: 5000,
      operation: 'LOAN',
      result: 'OK',
      order: asc
    };

    params = {...params, start_at: state.historicDataStartAt};
    params = {...params, end_at: state.historicDataEndAt};
    
    const respuesta = await loanService.userTraces(params);
    let data = respuesta.filter(({ result }) => !/error/ig.test(result))
      .map(element => ({
        'Usuario': element.username,
        'Operación': element.operation,
        'Código de barras': element.barcode,
        'Título': element.company,
        'Resultado': element.result,
        'Fecha de transacción': element.inserted_at ? formatDate(element.inserted_at) : element.inserted_at,
        'Fecha de devolución': element.data ? formatDateExtern(element.data) : element.data,
      }));
    setState(prev => ({
      ...prev,
      historicData: data,
      slicedHistoricData: data.slice(0, 10),
    }));
    setLoading(false);
  }

  // =======================================================
  /// 
  // =======================================================
  useEffect(() => {
    getLoansAndRenewals('month');
  }, []);

  const TablePageSection = state.showMonthlyData ? 
    (
      <Card tableCard>
        <TableActions title='Consolidado Mensual' count={0} onResetClick={() => getLoansAndRenewals('range')} />

        {loading && <LinearProgress id='usersProgress' />}

        <Grid>

          <Workbook
            filename='consolidadoMensual.xlsx'
            element={
            <Button
              raised
              primary
              iconEl={<FlatOrIconButton
                iconChildren='folder'
                style={{ color: '#fff' }}>{loading ? 'Cargando...' : '»Exportar'}</FlatOrIconButton>} 
            />
            }>
            <Workbook.Sheet
              data={state.loanAndRenewalsTableData}
              name='Consolidado Mensual'>
              <Workbook.Column
                label='Mes'
                value='Mes' />
              <Workbook.Column
                label='Préstamos'
                value='Préstamos' />
              <Workbook.Column
                label='Renovaciones'
                value='Renovaciones' />
            </Workbook.Sheet>
          </Workbook>
        </Grid>

        <DataTable baseId='dynamic-content-desserts'>
          {state.loanAndRenewalsTableData.length === 0 && (
            <tbody>
              <tr>
                <td style={{textAlign: 'center', padding: '10px'}}>Sin Registro</td>
              </tr>
            </tbody>
          )}
          {state.loanAndRenewalsTableData.length > 0 && (
            <React.Fragment>
              <TableHeader>
                <TableRow>
                  {Object.keys(state.slicedLoanAndRenewalsTableData[0]).map((header, i) => (
                    <TableColumn key={`${header}_${i}`}>{header}</TableColumn>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {state.slicedLoanAndRenewalsTableData.map((item, i) => (
                  <TableRow key={`${item.id}_${i}`}>
                    {Object.keys(item).map(key => (
                      <TableColumn key={key}>{item[key]}</TableColumn>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TablePagination
                rows={state.loanAndRenewalsTableData.length}
                rowsPerPageLabel='Registros por página'
                onPagination={handleLoanAndRenewalsPagination}
              />
            </React.Fragment>
          )}
        </DataTable>
      </Card>
    )
    : (
      <Card tableCard>
        <TableActions title='Histórico Préstamos' count={0} onResetClick={getHistoricData} />

        {loading && <LinearProgress id='usersProgress' />}
        <label for="accept">
          <input type="checkbox" id="chb_asc" name="accept"/>
          Orden Ascendente
        </label>
        <Grid>
          <DatePicker
            id='date-picker-auto-ok'
            label='Desde'
            autoOk
            className='md-cell md-cell--bottom'
            onChange={(value) => setState(prev => ({...prev, historicDataStartAt: value.replace(/\//g, '-').split('-').reverse().join('-')}))}
            locales='es-ES'
          />
          <DatePicker
            id='date-picker-auto-ok'
            label='Hasta'
            autoOk
            className='md-cell md-cell--bottom'
            onChange={(value) => setState(prev => ({...prev, historicDataEndAt: value.replace(/\//g, '-').split('-').reverse().join('-')}))}
            locales='es-ES'
          />
          <Button
            type='submit'
            className='md-cell md-cell--bottom'
            onClick={getHistoricData}
            primary
            flat
          >
            Buscar
          </Button>
          <Workbook
            filename='ReporteDePrestamos.xlsx'
            element={
              <Button
                raised
                primary
                iconEl={<FlatOrIconButton
                  iconChildren='folder'
                  style={{ color: '#fff' }}>{loading ? 'Cargando...' : '»Exportar'}</FlatOrIconButton>} 
              />
            }>
            <Workbook.Sheet
              data={state.historicData}
              name='Reportes'>
              <Workbook.Column
                label='Usuario'
                value='Usuario' />
              <Workbook.Column
                label='Operación'
                value='Operación' />
              <Workbook.Column
                label='Código de barras'
                value='Código de barras' />
              <Workbook.Column
                label='Título'
                value='Título' />
              <Workbook.Column
                label='Resultado'
                value='Resultado' />
              <Workbook.Column
                label='Fecha de transacción'
                value='Fecha de transacción' />
              <Workbook.Column
                label='Fecha de devolución'
                value='Fecha de devolución' />
            </Workbook.Sheet>
          </Workbook>
        </Grid>
        <DataTable baseId='dynamic-content-desserts'>
          {state.historicData.length === 0 && (
            <tbody>
              <tr>
                <td style={{textAlign: 'center', padding: '10px'}}>Sin Registro</td>
              </tr>
            </tbody>
          )}
          {state.slicedHistoricData.length > 0 && (
            <React.Fragment>
              <TableHeader>
                <TableRow>
                  {Object.keys(state.slicedHistoricData[0]).map((header, i) => (
                    <TableColumn key={`${header}_${i}`}>{header}</TableColumn>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {state.slicedHistoricData.map((item, i) => (
                  <TableRow key={`${item.id}_${i}`}>
                    {Object.keys(item).map(key => (
                      <TableColumn key={key}>{item[key]}</TableColumn>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TablePagination
                rows={state.historicData.length}
                rowsPerPageLabel='Registros por página'
                onPagination={handleHistoricDataPagination}
              />
            </React.Fragment>
          )}
        </DataTable>
      </Card>
    );

  return (
    <Grid style={{ width: "100%" }}>
      <Cell size={12}>
        <Card style={styles.card}>
          <Grid>
            <CardTitle title={state.loan.total} subtitle='Préstamos' />
            <CardTitle title={state.renewals.total} subtitle='Renovaciones' />
            <CardTitle title={''} subtitle='' />
          </Grid>
          <CardText>

            {(loading) && (<LinearProgress id='loanProgress' />)}

            {state.loan.data && (
              <React.Fragment>
                <hr />

                <SelectionControlGroup
                  id="selection-control-group-radios"
                  name="radio-example"
                  type="radio"
                  defaultValue="month"
                  value={state.filter_option}
                  onChange={ event => {
                    if(event === 'range') {
                      setState(prev => ({ ...prev, showLoanCustomRangePickers: true, filter_option: event }))
                    }else {
                      setState(prev => ({
                        ...prev,
                        showLoanCustomRangePickers: false,
                        showMonthlyData: false, 
                        filter_option: event
                      }));
                      getLoansAndRenewals(event);
                    }
                  }}
                  controls={[{
                    label: 'Hoy',
                    value: 'day',
                  }, {
                      label: 'Últimos 7 días',
                      value: 'week',
                  }, {
                      label: 'Último Mes',
                      value: 'month',
                  }, {
                      label: 'Consolidado Mensual',
                      value: 'range',
                  }]}
                />

                {
                  state.showLoanCustomRangePickers && (
                    <div>
                      <div>
                        <SelectField
                          simplifiedMenu
                          id='start-month-select-field'
                          label='Mes (Inicio)'
                          placeholder='Mes (Inicio)'
                          className='md-cell'
                          menuItems={MONTH_ITEMS}
                          value={state.loanStartMonth}
                          onChange={(value, index) => {
                              setState(prev => ({
                                ...prev,
                                loanStartMonth: value,
                                loanStartMonthNumber: '' + (index+1)
                              }))
                            }
                          }
                        />
                        <SelectField
                          id='start-year-select-field'
                          label='Año (Inicio)'
                          placeholder='Año (Inicio)'
                          className='md-cell'
                          menuItems={YEAR_ITEMS()}
                          simplifiedMenu={false}
                          value={state.loanStartYear}
                          onChange={(value) => {
                              setState(prev => ({...prev, loanStartYear: value}))
                            }
                          }
                        />
                      </div>
                      
                      <div>
                        <SelectField
                          simplifiedMenu
                          id='end-month-select-field'
                          label='Mes (Fin)'
                          placeholder='Mes (Fin)'
                          className='md-cell'
                          menuItems={MONTH_ITEMS}
                          value={state.loanEndMonth}
                          onChange={(value, index) => {
                              setState(prev => ({
                                ...prev,
                                loanEndMonth: value,
                                loanEndMonthNumber: '' + (index+1)
                              }))
                            }
                          }
                        />
                        <SelectField
                          simplifiedMenu
                          id='end-year-select-field'
                          label='Año (Fin)'
                          placeholder='Año (Fin)'
                          className='md-cell'
                          menuItems={YEAR_ITEMS()}
                          value={state.loanEndYear}
                          onChange={(value, index) => {
                              setState(prev => ({...prev, loanEndYear: value}))
                            }
                          }
                        />
                      </div>
                      
                      <Button
                        primary
                        flat
                        swapTheming
                        type='submit'
                        className='md-cell md-cell--bottom'
                        onClick={() => getLoansAndRenewals('range')}
                      >
                        Buscar
                      </Button>
                    </div>
                  )
                }

                <hr />

                <CardText>{state.chartInfoText}</CardText>

                {
                  state.showMonthlyData ? (

                    <Grid>
                      <BarChart
                        width={480}
                        height={300}
                        data={state.loan.data}
                        dataKeyX='Fecha'
                        dataKeyY='Préstamos'
                        barColor='#8884d8'
                      />
                      <BarChart
                        width={480}
                        height={300}
                        data={state.renewals.data}
                        dataKeyX='Fecha'
                        dataKeyY='Renovaciones'
                        barColor='#82ca9d'
                      />
                    </Grid>

                  ) : (

                    <Grid>
                      <LineChart
                        width={480}
                        height={300}
                        data={state.loan.data}
                        dataKeyX='Fecha'
                        dataKeyY='Préstamos'
                        lineColor='#8884d8'
                      />
                      <LineChart
                        width={480}
                        height={300}
                        data={state.renewals.data}
                        dataKeyX='Fecha'
                        dataKeyY='Renovaciones'
                        lineColor='#82ca9d'
                      />
                    </Grid>

                  )
                }
                
              </React.Fragment>
            )}

          </CardText>
        </Card>

        {TablePageSection}
        
      </Cell>
    </Grid>
  );
}

export default LoanReport;
