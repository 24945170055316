import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Workbook from "react-excel-workbook";

import {
    DataTable,
    TableHeader,
    TableBody,
    TableRow,
    TableColumn,
    Card,
    CardTitle,
    CardText,
    LinearProgress,
    TablePagination,
    Grid,
    Cell, 
    Button,
    DatePicker,
} from 'react-md';

import { baseUrl } from './../../../services/endpoints';

import PieChart from './../../../components/molecules/charts/pieChart';
import TableActions from './../../../components/molecules/tableActions';
import FlatOrIconButton from "../../../components/atoms/flatOrIconButton";

import { optionsGET } from "../../../services/options";

const ProgramaAcademico = () => {

    const [loading, setLoading] = useState(false);
    const [academics_loading, setAcademicLoading] = useState(false);
    const [start_date, setStartDate] = useState(moment());
    const [end_date, setEndDate] = useState(moment());
    const [state, setState] = useState({
        excelData: [],
        data: [],
        operation: '',
        slicedData: [],
        academics: {
            data: [],
            total: '0',
        },
    });

    const styles = {
        card: {
            borderRadius: '2px',
            marginBottom: '2rem',
        },
    };

    // =======================================================
    /// 
    // =======================================================
    const handlePagination = (start, rowsPerPage) => {
        setState(prev => ({
            ...prev,
            slicedData: prev.data.slice(start, start + rowsPerPage)
        }));
    };
    
    // =======================================================
    /// 
    // =======================================================
    const getData = async () => {
        setLoading(true);
        setAcademicLoading(true);
        setState( prev => ({
            ...prev,
            data: [],
            slicedData: []
        }));

        //return fetch('http://52.14.65.118:4000/user_traces/academics', optionsGET())
        const params = `?start_at=${moment(start_date).format('YYYY-MM-DD')}&end_at=${moment(end_date).format('YYYY-MM-DD')}`;
        const resp = await fetch(`${baseUrl}user_traces/academics${params}`, optionsGET());
        // console.log(await resp.clone(),await resp.clone().json());
        const response = resp.ok ? await resp.json() : [];
        
        let data;
        let excelData;

        excelData = response.filter(({ result }) => !/error/ig.test(result))
            .map(({ username, department, inserted_at }) =>
                ({
                    department,
                    username,
                    inserted_at: moment(inserted_at).format('DD/MM/YYYY HH:mm')
                }));

        data = _.orderBy(_.map(response, element => {
            let ref = {
                'Programa académico': null,
                'Usuario': null,
                'Fecha': null,
            };

            if (element.department) ref['Programa académico'] = (element.department) ? element.department : 'Sin información';
            if (element.username) ref['Usuario'] = element.username;
            if (element.operation) ref['Operación'] = element.operation;
            if (element.result) ref['Resultado'] = element.result;
            if (element.data) ref['Información'] = element.data;
            if (element.inserted_at) ref['Fecha'] = moment(element.inserted_at).format('DD/MM/YYYY HH:mm');

            return ref;
        }, rps => moment(rps.Fecha).unix()));

        data.sort((a, b) => {
            const keyA = new Date(a.Fecha);
            const keyB = new Date(b.Fecha);
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
        });

        const academics = response.map(item => ({ name: item.department || 'Desconocido' }))
            .reduce(function (arr, item) {
                let found = false;

                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].name === item.name) {
                        found = true;
                        arr[i].count++;
                    }
                }

                if (!found) {
                    item.count = 1;
                    arr.push(item);
                }

                return arr;
            }, []);

        
        setState(prev => ({
            ...prev,
            excelData,
            data,
            slicedData: data.slice(0, 10),
            academics: {
                data: academics,
                total: academics.reduce((total, element) => {
                    return total + element.count;
                }, 0),
            },
        }));

        setLoading(false);
        setAcademicLoading(false);
            
    }

    // =======================================================
    /// 
    // =======================================================
    useEffect(() => {
        getData();
    }, []);
        
    return (
        <Grid style={{width:"100%"}}>
            <Cell size={12}>

                <Card style={styles.card}>
                    <CardTitle title={state.academics.total} subtitle='Préstamos por programa académico' />
                    <CardText>
                        {academics_loading && (<LinearProgress id='academicsProgress' />)}

                        {state.academics.data && (
                            <Grid>
                                <Cell size={12}>
                                    <PieChart
                                        width={400}
                                        height={220}
                                        data={state.academics.data}
                                        dataKey='count'
                                    />
                                </Cell>
                            </Grid>
                        )}

                    </CardText>
                </Card>

                <Card tableCard>
                    <TableActions
                        title='Préstamos por Programa académico'
                        count={0}
                        onResetClick={getData} />

                    <Grid>
                        <DatePicker
                            id='date-picker-auto-ok'
                            label='Desde'
                            autoOk
                            className='md-cell md-cell--bottom'
                            name="historicDataStartAt"
                            value={state.historicDataStartAt}
                            onChange={(value, date) =>
                                setStartDate(moment(date).format('YYYY-MM-DD'))
                            }
                            locales='es-ES'
                        />
                        <DatePicker
                            id='date-picker-auto-ok'
                            label='Hasta'
                            autoOk
                            className='md-cell md-cell--bottom'
                            name="historicDataEndAt"
                            value={state.historicDataEndAt}
                            onChange={(value, date) =>
                                setEndDate(moment(date).format('YYYY-MM-DD'))
                            }
                            locales='es-ES'
                        />
                        <Button
                            type='submit'
                            className='md-cell md-cell--bottom'
                            onClick={getData}
                            primary
                            flat>
                            Buscar
                        </Button>
                    </Grid>

                    <Workbook
                        filename='Prestamos por Programa académico.xlsx'
                        element={
                            <Button
                                style={{ marginLeft: 20 }}
                                raised
                                primary
                                iconEl={<FlatOrIconButton
                                    iconChildren='folder'
                                    style={{ color: '#fff' }}>{loading ? 'Cargando...' : '»Exportar'}</FlatOrIconButton>} />
                        }>

                        <Workbook.Sheet
                            data={state.excelData}
                            name='Reportes'>
                            <Workbook.Column
                                label='Programa académico'
                                value='department' />
                            <Workbook.Column
                                label='Usuario'
                                value='username' />
                            <Workbook.Column
                                label='Fecha'
                                value='inserted_at' />
                        </Workbook.Sheet>
                    </Workbook>

                    {loading && <LinearProgress id='usersProgress' />}

                    <DataTable baseId='dynamic-content-desserts'>
                        {state.data.length == 0 && (
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center', padding: '10px' }}>Sin Registro</td>
                                </tr>
                            </tbody>
                        )}
                        {state.slicedData.length > 0 && (
                            <React.Fragment>
                                <TableHeader>
                                    <TableRow>
                                        {state.slicedData.length > 0 && Object.keys(state.slicedData[0]).map((header, i) => (
                                            <TableColumn key={header}>{header}</TableColumn>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {state.slicedData.map((item, i) => (
                                        <TableRow key={item.id}>
                                            {Object.keys(item).map(key => (
                                                <TableColumn key={key}>{item[key]}</TableColumn>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TablePagination
                                    rows={state.data.length}
                                    rowsPerPageLabel='Registros por página'
                                    onPagination={handlePagination}
                                />
                            </React.Fragment>
                        )}
                    </DataTable>
                </Card>
            </Cell>
        </Grid>
    );
};

export default ProgramaAcademico;