import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {
  List,
  Divider,
  ListItem,
  FontIcon,
} from 'react-md';

import {logoutRequest} from './../../../store/reducers/user';

import NavLink from './../../atoms/navLink';

const NavList = props => {

  const [nav, setNav] = useState([
    {label: 'Inicio', icon: 'home', to: '/', exact: true},
    // {label: 'Reservas', icon: 'calendar_today', to: '/reservation'},
    {label: 'Usuarios', icon: 'people', to: '/users'},
    {label: 'Reportes', icon: 'assignment', to: '/reports'},
    // {label: 'Salas', icon: 'meeting_room', to: '/rooms'},
    // {label: 'Horarios Salas', icon: 'calendar_today', to: '/rooms/schedule'},
    // {label: 'Referencistas', icon: 'school', to: '/tutors'},
    // {label: 'Horarios Referencistas', icon: 'calendar_today', to: '/tutors/schedule'},
    // {label: 'Auditoria', icon: 'assignment_turned_in', to: '/audit'},
    {label: 'Configuración', icon: 'settings', to: '/settings'},
  ]);

  useEffect(() => {
    if (props.appType !== undefined) {
      if (props.appType !== null) {
        let itemsAdded = [...nav];

        props.appType.map(item => {
          switch (item) {
            case 'loan': itemsAdded = itemsAdded.concat([
                {label: 'Auditoria', icon: 'assignment_turned_in', to: '/audit'},
              ]);
              break;

            case 'rooms':
              if (!itemsAdded.some(item => {return item.label === 'Reservas'})) {
                itemsAdded = itemsAdded.concat([
                  {label: 'Reservas', icon: 'calendar_today', to: '/reservation'},
                ]);
              }
              itemsAdded = itemsAdded.concat([
                {label: 'Salas', icon: 'meeting_room', to: '/rooms'},
                {label: 'Horarios Salas', icon: 'calendar_today', to: '/rooms/schedule'},
              ]);
              break;

            case 'tutors':
              if (!itemsAdded.some(item => {return item.label === 'Reservas'})) {
                itemsAdded = itemsAdded.concat([
                  {label: 'Reservas', icon: 'calendar_today', to: '/reservation'},
                ]);
              }
              itemsAdded = itemsAdded.concat([
                {label: 'Referencistas', icon: 'school', to: '/tutors'},
                {label: 'Horarios Referencistas', icon: 'calendar_today', to: '/tutors/schedule'},
              ]);
              break;

            default: console.log('No se encontró a qué item del navList pertenece el módulo...');
          }
        });
        
        setNav(itemsAdded);
      }
    }
  }, [props.appType]);

  const doLogout = () => {
    return props.logoutRequest();
  };

  return (
    <List style={styles.list}>
      {nav.map((item, index) => (
        <NavLink
          key={index}
          label={item.label}
          icon={item.icon}
          to={item.to}
          exact={item.exact}
        />
      ))}
      <Divider/>
      <NavLink
        key={999}
        label="Mi perfil"
        icon="person"
        to="/profile"
      />
      <Divider/>
      <ListItem
        primaryText="Cerrar Sessión"
        leftIcon={<FontIcon>exit_to_app</FontIcon>}
        onClick={() => doLogout()}
      />
    </List>
  );
};

const styles = {
  list: {
    backgroundColor: 'transparent',
    position: 'sticky',
    top: '6rem',
  }
};

const mapStateToProps = state => ({
  appType: state.user.appType,
});

const mapDispatchToProps = dispatch => ({
  logoutRequest: () => dispatch(logoutRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavList);
